import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import MerchantActionCreators from '../../redux/actions/merchant';

/** @typedef {import('../../redux/reducers').RootState} RootState */

const {
  createStripeSubscription: createStripeSubscriptionAction,
} = MerchantActionCreators;

const PaymentForm = ({ merchantId, stripePlanId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const customerSubscriptionLoading = useSelector(
    (/** @type {RootState} */state) => state.MerchantReducer.customerSubscriptionLoading,
    shallowEqual,
  );
  const customerSubscriptionError = useSelector(
    (/** @type {RootState} */state) => state.MerchantReducer.customerSubscriptionError,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const [errorToDisplay, setErrorToDisplay] = useState('');
  const [subscribing, setSubscribing] = useState(false);

  useEffect(() => {
    setErrorToDisplay(customerSubscriptionError);
  }, [customerSubscriptionError]);

  useEffect(() => {
    setSubscribing(customerSubscriptionLoading);
  }, [customerSubscriptionLoading]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setSubscribing(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      throw new Error('cardElement was not found');
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error || !paymentMethod) {
      console.error('createPaymentMethod error', error);
      setSubscribing(false);
      setErrorToDisplay(error?.message || 'An unknown error occurred');
      return;
    }

    dispatch(
      createStripeSubscriptionAction(
        merchantId,
        stripePlanId,
        paymentMethod.id,
      ),
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe}>
        {subscribing ? 'Subscribing...' : 'Subscribe'}
      </button>
      <div>{errorToDisplay || null}</div>
    </form>
  );
};

export default PaymentForm;
