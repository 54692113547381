import React from 'react';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthActionCreators from '../redux/actions/auth';

const Logout = ({ actions: { logout } }) => {
  logout();

  return <Redirect to="/login" />;
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(AuthActionCreators, dispatch),
});

export default connect(null, mapDispatchToProps)(Logout);
