import React from 'react';
import { Form, Field } from 'react-final-form';
import { autoresponderValidate as validate } from '../../utils/validation';
import { BPToggle } from '../BPToggle';

export const TYPE_EDIT = 'edit';
export const TYPE_CREATE = 'create';

export const AutoresponderForm = ({
  initialValues,
  loading,
  onSubmit,
  type = TYPE_EDIT,
}) => {
  const buttonText = type === TYPE_EDIT ? 'Save Changes' : 'Create Smartlink';

  return (
    <Form
      validate={validate}
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({
        handleSubmit,
        submitError,
        submitting,
        form,
        pristine,
        valid,
      }) => (
        <form onSubmit={handleSubmit} className="merchant-details-form">
          <Field
            name="merchantId"
            component="input"
            type="hidden"
            className="form-control"
          />
          <div className="form-group">
            <div className="field form-inline">
              <label htmlFor="isActive">Active?</label>
&nbsp;&nbsp;
              <Field
                id="isActive"
                name="isActive"
                component={BPToggle}
                type="checkbox"
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="field">
              <Field
                name="triggerText"
                component="textarea"
                placeholder="Trigger text"
                className="form-control"
                rows="4"
              />
            </div>
          </div>
          <div className="form-group">
            <label>
              Response (
              {'{brand_name}, {first_name}'}
              )
            </label>
            <div className="field">
              <Field
                name="messageText"
                component="textarea"
                placeholder="messageText"
                className="form-control"
                rows="4"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="alternativeUrl">Alternative URL (optional)</label>
            <Field id="alternativeUrl" name="alternativeUrl">
              {({ input, meta }) => (
                <div className="field">
                  <input
                    {...input}
                    className="form-control"
                    type="text"
                    placeholder="https://..."
                  />
                  {meta.error && (
                    <span className="form-error">{meta.error}</span>
                  )}
                </div>
              )}
            </Field>
          </div>
          <div className="form-group">
            <div className="field form-inline">
              <label htmlFor="shouldTriggerAlert">Also trigger alert?</label>
&nbsp;&nbsp;
              <Field
                id="shouldTriggerAlert"
                name="shouldTriggerAlert"
                component={BPToggle}
                type="checkbox"
                className="form-control"
              />
            </div>
          </div>
          {submitError && <span className="form-error">{submitError}</span>}
          <div className="form-group buttons">
            <button
              type="submit"
              disabled={!valid || submitting || pristine}
              className="btn btn-sm btn-primary"
            >
              {loading ? 'Saving...' : buttonText}
            </button>
            {!pristine && (
              <button
                type="button"
                onClick={form.reset}
                className="btn btn-sm btn-link"
              >
                Cancel
              </button>
            )}
          </div>
        </form>
      )}
    />
  );
};
