import React, { useCallback, useState, useMemo } from 'react';
import UsersTable from './UsersTable';
import UserDetailsForm from './forms/UserDetailsForm';

const UsersTab = ({
  merchant, handleSubmit, handleDeleteUser, loading,
}) => {
  const [user, setUser] = useState({});
  const onClick = (userParam) => {
    setUser({
      id: userParam.user.userId,
      ...userParam.user,
      role: userParam.role,
    });
  };
  const resetUser = useCallback(() => {
    setUser({});
  }, []);
  const isNewUser = useMemo(() => Object.keys(user).length === 0, [user]);
  return (
    <div>
      <div className="row">
        <div className="col-md-6 col-xl-7">
          <div className="merch-card">
            <span className="merch-title">Users</span>
            <UsersTable users={merchant.users} onClick={onClick} />
          </div>
        </div>
        <div className="col-md-6 col-xl-5">
          <div className="merch-card">
            <span className="merch-title">
              {isNewUser ? 'Add User' : 'Edit User'}
            </span>
            <UserDetailsForm
              loading={loading}
              resetUser={resetUser}
              initialValues={user}
              isNewUser={isNewUser}
              onSubmit={handleSubmit}
              handleDeleteUser={handleDeleteUser}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersTab;
