import React from 'react';

const BPField = ({
  input,
  readOnly = false,
  disabled = false,
  placeholder,
  className,
  groupContent,
  overrideErr = false,
  type,
  meta: { touched, error },
}) => {
  const inputToUse = { ...input };
  if (type && !input.type) {
    inputToUse.type = type;
  }

  return (
    <div className={groupContent && 'input-group'}>
      <input
        {...inputToUse}
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        className={
          className + ((overrideErr || touched) && error ? ' error' : '')
        }
        autoComplete="something-unsupported"
        autofill="off"
      />
      {groupContent}
      {touched && error && <span className="field-error">{error}</span>}
    </div>
  );
};

export default BPField;
