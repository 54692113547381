import React from 'react';
import Sidebar from './Sidebar';

const PageBuilder = ({ children }) => (
  <div className="page-builder">
    <Sidebar />
    <div className="content">{children}</div>
  </div>
);

export default PageBuilder;
