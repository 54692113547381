import React from 'react';
import PageBuilder from '../components/global/PageBuilder';

const Dashboard = () => (
  <PageBuilder>
    <div className="a-view dashboard">
      <div className="container">
        <h2>Welcome to dashboard</h2>
      </div>
    </div>
  </PageBuilder>
);

export default Dashboard;
