import React, { useCallback } from 'react';
import { Form, Field } from 'react-final-form';
import BPField from './BPField';
import { userDetailsValidate } from '../../utils/validation';

const OPERATIONS = {
  addNew: 'addNew',
  addExisting: 'addExisting',
};

const UserDetailsForm = ({
  initialValues,
  isNewUser,
  loading,
  onSubmit,
  handleDeleteUser,
  resetUser,
}) => {
  const clearForm = useCallback(
    (form) => {
      resetUser();
      const currentOperation = form.getState().values.operation || OPERATIONS.addNew;
      form.restart();
      form.change('operation', currentOperation);
    },
    [resetUser],
  );
  const onDeleteClick = async (form) => {
    await handleDeleteUser(initialValues.id);
    clearForm(form);
  };
  const onSubmitLocal = async (data) => onSubmit({
    ...data,
    operation: data?.operation || OPERATIONS.addNew,
  });

  return (
    <Form
      validate={userDetailsValidate}
      onSubmit={onSubmitLocal}
      initialValues={initialValues}
      render={({
        handleSubmit,
        submitError,
        submitting,
        form,
        pristine,
        valid,
      }) => {
        const handleSubmitLocal = async (event) => {
          await handleSubmit(event);
          clearForm(form);
        };
        const currentOperation = form.getState().values.operation || OPERATIONS.addNew;
        const shouldShowNewUserFields = !isNewUser || currentOperation === OPERATIONS.addNew;
        return (
          <form onSubmit={handleSubmitLocal} className="merchant-details-form">
            {isNewUser && (
            <div className="form-group brand-color">
              <label htmlFor="role">Operation</label>
              <div className="field">
                <Field
                  placeholder="Operation"
                  name="operation"
                  component="select"
                  className="form-control"
                >
                  <option value={OPERATIONS.addNew}>Add new user</option>
                  <option value={OPERATIONS.addExisting}>Add existing user to this merchant</option>
                </Field>
              </div>
            </div>
            )}
            <div className="form-group brand-color">
              <label htmlFor="email">Email address</label>
              <div className="field">
                <Field
                  name="email"
                  component={BPField}
                  type="email"
                  placeholder="Email address"
                  className="form-control"
                />
              </div>
            </div>
            {(shouldShowNewUserFields) && (
            <div className="form-group brand-color">
              <label htmlFor="name">Username</label>
              <div className="field">
                <Field
                  name="name"
                  component={BPField}
                  type="text"
                  placeholder="Username"
                  className="form-control"
                />
              </div>
            </div>
            )}
            {(shouldShowNewUserFields) && (
            <div className="form-group brand-color">
              <label htmlFor="pw">Password</label>
              <div className="field">
                <Field
                  name="pw"
                  component={BPField}
                  type="password"
                  placeholder="Password"
                  className="form-control"
                />
              </div>
            </div>
            )}
            <div className="form-group brand-color">
              <label htmlFor="role">Role</label>
              <div className="field">
                <Field
                  placeholder="Role"
                  name="role"
                  component="select"
                  className="form-control"
                >
                  <option label=" " />
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                  <option value="agent">Agent</option>
                </Field>
              </div>
            </div>
            <div className="form-group brand-color form-check">
              <Field
                placeholder="disabled"
                name="isDisabled"
                component={BPField}
                type="checkbox"
                className="form-check-input"
              />
              <label htmlFor="isDisabled" className="form-check-label">Disabled</label>
            </div>
            {submitError && <span className="form-error">{submitError}</span>}
            <div className="form-group buttons">
              <button
                type="submit"
                disabled={!valid || submitting || pristine}
                className="btn btn-primary"
              >
                {loading && 'Saving...' }
                {!loading && isNewUser
                  ? 'Add new user'
                  : 'Save Changes' }
              </button>
              <button
                type="button"
                onClick={() => clearForm(form)}
                className="btn btn-link"
              >
                Cancel
              </button>
              {initialValues.id && pristine && (
                <button
                  type="button"
                  onClick={() => onDeleteClick(form)}
                  className="btn btn-link"
                >
                  remove user access
                </button>
              )}
            </div>
          </form>
        );
      }}
    />
  );
};

export default UserDetailsForm;
