import { CustomerActionTypes } from '../../types';

export const initialState = {
  loading: false,
  customer: {},
  customerErr: null,
  customerNoteErr: null,
};

export const CustomerReducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case CustomerActionTypes.GET_CUS_REQ: {
      return {
        ...state,
        loading: true,
        customerErr: null,
      };
    }
    case CustomerActionTypes.GET_CUS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          customerErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        customerErr: null,
        customer: {
          ...state.customer,
          [payload.id]: payload,
        },
      };
    }
    case CustomerActionTypes.ADD_CUSNOTE_REQ: {
      return {
        ...state,
        loading: true,
        customerNoteErr: null,
      };
    }
    case CustomerActionTypes.ADD_CUSNOTE_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          customerNoteErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        customerNoteErr: null,
      };
    }
    default:
      return state;
  }
};
