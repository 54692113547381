import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({ dsn: process.env.SENTRY_DSN });

ReactDOM.render(
  <Sentry.ErrorBoundary fallback="An error has occured">
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);
serviceWorker.unregister();
