import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import currencies from '../../utils/currencies';
import BPField from './BPField';
import { newMerchantValidate } from '../../utils/validation';

const STORE_ECOM_SHOPIFY = 'shopify';
const STORE_ECOM_CUSTOM_STORE = 'custom';

function MerchantCreationForm(props) {
  const {
    handleSubmit,
    handleCancel,
    valid,
    creationError,
    submitting,
    pristine,
    readOnly,
    selectedEcomStore,
  } = props;

  return (
    <div className="merchant-creation-container">
      {!readOnly && <h1>Create new Merchant</h1>}
      <form onSubmit={handleSubmit} className="merchant-creation-form">
        <h4>Store information</h4>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group brand-color">
              <label htmlFor="storeName">Merchant name</label>
              <div className="field">
                <Field
                  name="storeName"
                  component={BPField}
                  type="text"
                  placeholder="Shop Name"
                  className="form-control"
                  disabled={readOnly}
                />
              </div>
            </div>
            <div className="form-group brand-color">
              <label htmlFor="storeCustomDomain">
                Merchant’s full website address (with &quot;https://&quot; part)
              </label>
              <div className="field">
                <Field
                  name="storeCustomDomain"
                  component={BPField}
                  type="text"
                  placeholder="https://www.blueprint.store"
                  className="form-control"
                  disabled={readOnly}
                />
              </div>
            </div>
            <div className="form-group brand-color">
              <label htmlFor="storePhone">Phone number</label>
              <div className="field">
                <Field
                  name="storePhone"
                  component={BPField}
                  type="text"
                  placeholder="447857033883"
                  className="form-control"
                  disabled={readOnly}
                />
              </div>
            </div>
          </div>
          <div className=" col-md-6">
            <div className="form-group brand-color">
              <label htmlFor="storeCurrency">Currency</label>
              <div className="field">
                <Field
                  name="storeCurrency"
                  component="select"
                  className="form-control"
                  disabled={readOnly}
                >
                  <option value="">[Please select...]</option>
                  {Object.keys(currencies).map((currencyCode) => (
                    <option key={currencyCode} value={currencyCode}>
                      {currencyCode}
                      {' '}
                      (
                      {currencies[currencyCode].symbol}
                      )
                    </option>
                  ))}
                </Field>
              </div>
            </div>
            <div className="form-group brand-color">
              <label htmlFor="storeCustomDomain">Ecom type</label>
              <div className="field">
                <Field
                  name="storeEcomType"
                  component="select"
                  className="form-control"
                  disabled={readOnly}
                  defaultValue={STORE_ECOM_SHOPIFY}
                >
                  <option key="shopify" value={STORE_ECOM_SHOPIFY}>
                    Shopify
                  </option>
                  <option key="customDomain" value={STORE_ECOM_CUSTOM_STORE}>
                    Custom Store
                  </option>
                </Field>
              </div>
            </div>
            <div className="form-group brand-color">
              { selectedEcomStore === STORE_ECOM_SHOPIFY && (
              <div className="form-group brand-color">
                <label htmlFor="shopifyDomain">Merchant’s myshopify.com domain</label>
                <div className="field">
                  <Field
                    name="shopifyDomain"
                    component={BPField}
                    type="text"
                    placeholder="shop0.myshopify.com"
                    className="form-control"
                    disabled={readOnly}
                  />
                </div>
              </div>
              )}
              { selectedEcomStore === STORE_ECOM_CUSTOM_STORE && (
              <div className="form-group brand-color">
                <label htmlFor="deepLinkBaseUrl">Merchant’s deep link base url (checkout url)</label>
                <div className="field">
                  <Field
                    name="deepLinkBaseUrl"
                    component={BPField}
                    type="text"
                    placeholder="customer deeplink url"
                    className="form-control"
                    disabled={readOnly}
                  />
                </div>
              </div>
              )}
            </div>
          </div>
        </div>

        <h4>Initial user information</h4>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group brand-color">
              <label htmlFor="storeCustomDomain">Email address</label>
              <div className="field">
                <Field
                  name="initialUserEmail"
                  component={BPField}
                  type="email"
                  placeholder="john@example.com"
                  className="form-control"
                  disabled={readOnly}
                />
              </div>
            </div>
            <div className="form-group brand-color">
              <label htmlFor="initialUsername">Username</label>
              <div className="field">
                <Field
                  name="initialUsername"
                  component={BPField}
                  type="text"
                  placeholder="John"
                  className="form-control"
                  disabled={readOnly}
                />
              </div>
            </div>
            <div className="form-group brand-color">
              <label htmlFor="initialUserPassword">Password</label>
              <div className="field">
                <Field
                  name="initialUserPassword"
                  component={BPField}
                  type="password"
                  placeholder="Password"
                  className="form-control"
                  disabled={readOnly}
                />
              </div>
            </div>
          </div>
        </div>

        {creationError && <span className="form-error">{creationError}</span>}
        {!readOnly && (
        <div className="form-group buttons">
          <button
            type="submit"
            disabled={!valid || submitting || pristine}
            className="btn btn-primary"
          >
            {submitting ? 'Creating...' : 'Create'}
          </button>
          <button
            type="button"
            disabled={submitting}
            onClick={handleCancel}
            className="btn btn-link"
          >
            Cancel
          </button>
        </div>
        )}
      </form>
    </div>
  );
}

const selector = formValueSelector('brandCreationForm');

const mapStateToProps = (state, props) => ({
  initialValues: props.defaults,
  selectedEcomStore: selector(state, 'storeEcomType'),
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'brandCreationForm',
    validate: newMerchantValidate,
    enableReinitialize: true,
  }),
// @ts-ignore
)(MerchantCreationForm);
