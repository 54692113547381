import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Fuse from 'fuse.js';
import { withRouter } from 'react-router-dom';
import { BPConvSearchbox } from './BPConvSearchbox';
import MerchantActionCreators from '../redux/actions/merchant';

class MerchantsTable extends Component {
  constructor(props) {
    super(props);

    this.state = { query: '', allMerchants: [] };
  }

  // eslint-disable-next-line react/no-deprecated
  async componentWillMount() {
    const { actions } = this.props;
    try {
      await actions.getAllMerchants();
      const { allMerchantsErr, allMerchants } = this.props;
      if (allMerchantsErr) {
        console.log('allMerchantsErr', allMerchantsErr);
      } else {
        this.setState({ allMerchants });
      }
    } catch (e) {
      console.log('Error mounting MerchantsTable', e);
    }
  }

  handleChange = (query) => this.setState({ query });

  getMerchants = () => {
    const { query, allMerchants } = this.state;
    const fuseOptions = {
      shouldSort: true,
      threshold: 0.3,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['id', 'company', 'phone', 'platform', 'currency'],
    };
    const fuse = new Fuse([...allMerchants], fuseOptions);
    const theMerchants = (!query || query) && query.length === 0
      ? allMerchants
      : [...fuse.search(query).map((x) => x.item)];
    return theMerchants;
  };

  handleClick = (id) => {
    window.location.href = `/merchants/${id}`;
  };

  getLocalDateTime = (dateTimeString) => {
    if (!dateTimeString) {
      return '';
    }
    const date = new Date(dateTimeString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  render() {
    const { query } = this.state;

    return (
      <div className="all-merchants">
        <div className="search-bar">
          <BPConvSearchbox value={query} handleChange={this.handleChange} />
        </div>
        <div className="merchants-table bp-table">
          <div className="t-header row">
            <div className="col-md-1">Id</div>
            <div className="col-md-3">Company Name</div>
            <div className="col-md-2">Phone Number</div>
            <div className="col-md-1">Platform</div>
            <div className="col-md-1">Currency</div>
            <div className="col-md-2">Created</div>
            <div className="col-md-2">Installed</div>
          </div>
          <div className="t-body">
            {this.getMerchants().map((merchant) => (
              <div
                className="merchant-line row"
                key={merchant.id}
                onClick={() => this.handleClick(merchant.id)}
                role="button"
                tabIndex={0}
              >
                <div className="col-md-1">{merchant.id}</div>
                <div className="col-md-3">{merchant.company}</div>
                <div className="col-md-2">{merchant.phone}</div>
                <div className="col-md-1">{merchant.platform}</div>
                <div className="col-md-1">{merchant.currency}</div>
                <div className="col-md-2">
                  {this.getLocalDateTime(merchant.created)}
                </div>
                <div className="col-md-2">
                  {this.getLocalDateTime(merchant.storeInstalledAt)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allMerchantsErr: state.MerchantReducer.allMerchantsErr,
  allMerchants: state.MerchantReducer.allMerchants,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(MerchantActionCreators, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MerchantsTable),
);
