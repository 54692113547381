import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import MerchantActionCreators from '../redux/actions/merchant';
import PageBuilder from '../components/global/PageBuilder';
import MerchantsTable from '../components/MerchantsTable';
import MerchantCreation from '../components/forms/MerchantCreationForm';
import BTitle from '../components/global/BTitle';
import currencies from '../utils/currencies';

class Merchants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreationForm: false,
      submitting: false,
      creationError: null,
    };
  }

  handleSubmit = async (data) => {
    const {
      actions, submitting, toastManager, history,
    } = this.props;
    if (!submitting) {
      this.setState({ submitting: true, creationError: null });
      const theData = { ...data };
      theData.storeCurrencySymbol = currencies[data.storeCurrency].symbol;

      try {
        await actions.createMerchant(theData);
        const { merchantErr, merchantData } = this.props;
        if (merchantErr) {
          this.setState({ creationError: merchantErr });
          toastManager.add(merchantErr, {
            appearance: 'error',
            autoDismiss: true,
          });
        } else {
          toastManager.add('Merchant created successfully', {
            appearance: 'success',
            autoDismiss: true,
          });
          history.push(`/merchants/${merchantData.merchantId}`);
        }
      } catch (e) {
        console.error('error while creating a merchant', e);
        toastManager.add(`Something went wrong: ${e.message}`, {
          appearance: 'error',
          autoDismiss: true,
        });
        Sentry.captureException(e);
      }
    }
  };

  handleCancel = () => {
    this.setState({ showCreationForm: false });
  };

  showCreateBtn = () => (
    <button
      className="btn btn-primary create-new-merchant"
      onClick={() => this.setState({ showCreationForm: true })}
      type="button"
    >
      Create new merchant
    </button>
  );

  render() {
    const {
      showCreationForm,
      submitting,
      creationError,
      creationFormType,
    } = this.state;

    const defaults = {
      storeCurrency: '',
      storeEcomType: 'shopify',
      storeUsePublicApp: true,
      requestReadAllOrders: true,
    };

    return (
      <PageBuilder>
        <div className="a-view merchants">
          <div className="container">
            <BTitle text="Merchants" />
            <MerchantsTable />
            {showCreationForm ? (
              <MerchantCreation
                submitting={submitting}
                onSubmit={this.handleSubmit}
                handleCancel={this.handleCancel}
                creationError={creationError}
                creationFormType={creationFormType}
                defaults={defaults}
              />
            ) : (
              this.showCreateBtn()
            )}
          </div>
        </div>
      </PageBuilder>
    );
  }
}

const mapStateToProps = (state) => ({
  merchantErr: state.MerchantReducer.merchantErr,
  merchantData: state.MerchantReducer.merchantData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(MerchantActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(Merchants),
);
