import React from 'react';
import BImage from './BImage';

const Logo = (props) => {
  const { small = false } = props;
  return (
    <BImage
      src={
        small
          ? 'https://static.blueprint.store/logo_square_light.png'
          : 'https://static.blueprint.store/logo.svg'
      }
      className="logo"
      alt="Logo"
    />
  );
};

export default Logo;
