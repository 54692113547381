import React, { Component } from 'react';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import Routing from './components/Routing';
import './assets/sass/index.scss';
import store from './redux';

Modal.setAppElement('#root');

class App extends Component {
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    window.previousLocation = nextProps.location;
  }

  render() {
    return (
      <Provider store={store}>
        <Routing />
      </Provider>
    );
  }
}

export default App;
