import React from 'react';

const App = ({ app, onClick, index }) => (
  <div className="merchant-line row" role="button" tabIndex={index} onClick={() => onClick(app)}>
    <div className="col-md-1">{app.id}</div>
    <div className="col-md-4">{app.storeDomain}</div>
    <div className="col-md-5">{app.apiKey}</div>
    <div className="col-md-2">{app.apiSecret}</div>
  </div>
);

const ShopifyAppsTable = ({ apps, onClick }) => (
  <div className="users-table bp-table">
    <div className="t-header row">
      <div className="col-md-1">Id</div>
      <div className="col-md-4">Store Domain</div>
      <div className="col-md-5">API Key</div>
      <div className="col-md-2">API Secret</div>
    </div>
    <div className="t-body">
      {apps.length > 0 ? (
        apps.map((app, index) => <App key={app.id} app={app} onClick={onClick} index={index} />)
      ) : (
        <div className="merchant-line row">
          <div className="col text-center">No data</div>
          {' '}
        </div>
      )}
    </div>
  </div>
);

export default ShopifyAppsTable;
