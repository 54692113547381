import React, {
  useCallback,
  useEffect,
  memo,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';

const UploadPhoneNumbersTab = ({
  error, loading, merchant, onLeave, onUpload, uploadResult,
}) => {
  const [uploadProgress, setUploadProgress] = useState(/** @type {Number?} */(null));
  const [requestError, setRequestError] = useState(/** @type {String?} */ (null));

  useEffect(() => () => {
    onLeave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUploadProgress = (progressEvent) => {
    if (progressEvent?.type === 'progress') {
      try {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadProgress(Math.floor(progress));
      } catch (err) {
        // it is fine to ignore
      }
    }
  };

  const onDrop = useCallback(
    async (files) => {
      setUploadProgress(null);
      setRequestError(null);
      const formData = new FormData();
      formData.append('files', files[0]);
      formData.append('merchantId', merchant.id);

      try {
        await onUpload(formData, { onUploadProgress });
      } catch (requestErrorDetail) {
        if (typeof requestErrorDetail === 'string') {
          setRequestError(requestErrorDetail);
        } else {
          setRequestError('An unexpected error on the server side');
        }
      }
    },
    [merchant, onUpload],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div>
      <div className="row">
        <div className="col-md-6 col-xl-7">
          <div className="merch-card">
            <span className="merch-title">Upload Audience</span>

            <div {...getRootProps()}>
              <input {...getInputProps()} accept=".csv" />

              <button
                className="btn btn-upload btn-primary"
                disabled={loading}
                type="button"
              >
                {loading ? `Uploading ${uploadProgress || 0}%` : 'Upload'}
              </button>
            </div>

            <div className="phone-upload-result">
              {uploadResult && (
              <div>
                <p>
                  Success:
                  {' '}
                  <b>
                    {uploadResult.optedIn}
                    {' '}
                    subscribers
                  </b>
                  {' '}
                  added
                </p>
                <p>
                  {uploadResult.invalid}
                  {' '}
                  Invalid Numbers
                </p>
                <p>
                  {uploadResult.skippedOptedIn}
                  {' '}
                  Already Imported
                </p>
                <p>
                  {uploadResult.skippedOptedOut}
                  {' '}
                  Skipped (Unsubscribed in
                  Dashboard)
                </p>
              </div>
              )}

              {error && (
                <div className="text-danger">
                  Error:
                  {error}
                </div>
              )}

              {requestError && (
                <div className="text-danger">
                  Request Error:
                  {requestError}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(UploadPhoneNumbersTab);
