import axios from 'axios';
import { reset } from 'redux-form';
import { AuthActionTypes } from '../../types';
import { BlueprintAPI } from '../BlueprintAPI';

const SERVICE = 'blueprint-admin-backend';

const AuthActionCreators = {
  checkToken: () => async (dispatch) => {
    const sessionToken = await localStorage.getItem('sToken');
    const refreshToken = await localStorage.getItem('rToken');
    if (!sessionToken || !refreshToken) {
      dispatch({
        type: AuthActionTypes.AUTH_STATUS,
        error: 'No Auth',
      });
    } else {
      try {
        await BlueprintAPI(
          SERVICE,
          'token',
          {
            operation: 'check',
            sessionToken,
            refreshToken,
          },
          `${window.blueprintGetApiBaseURL()}/blueprint-admin-backend/token`,
        );
        dispatch({
          type: AuthActionTypes.AUTH_STATUS,
          error: null,
        });
      } catch (e) {
        dispatch({
          type: AuthActionTypes.AUTH_STATUS,
          error: 'No Auth',
        });
      }
    }
  },
  login: (data) => async (dispatch) => {
    dispatch({
      type: AuthActionTypes.LOGIN_REQ,
    });
    try {
      const response = await axios.post(
        `${window.blueprintGetApiBaseURL()}/${SERVICE}/login`,
        JSON.stringify(data),
      );
      if (response.data.status === 200) {
        localStorage.setItem('sToken', response.data.sessionToken);
        localStorage.setItem('rToken', response.data.refreshToken);
        dispatch({
          type: AuthActionTypes.LOGIN_RES,
          error: null,
        });
      } else {
        dispatch({
          type: AuthActionTypes.LOGIN_RES,
          error: response.data.message || 'An error occurred while logging in',
        });
      }
    } catch (e) {
      dispatch({
        type: AuthActionTypes.LOGIN_RES,
        error: e.message,
      });
    }
  },
  updatePassword: (data) => async (dispatch) => {
    dispatch({
      type: AuthActionTypes.UPDATE_PW_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem('sToken');
      const theData = { sessionToken, operation: 'update', ...data };
      await BlueprintAPI(SERVICE, 'updatePassword', theData);
      dispatch({
        type: AuthActionTypes.UPDATE_PW_RES,
      });
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: AuthActionTypes.UPDATE_PW_RES,
        error: e.data.message || 'Something went wrong',
      });
    }
  },
  resetForm: (formName) => async (dispatch) => {
    dispatch(reset(formName));
  },
  logout: () => async (dispatch) => {
    await localStorage.removeItem('sToken');
    await localStorage.removeItem('rToken');
    dispatch({
      type: 'LOGOUT',
    });
  },
};

export default AuthActionCreators;
