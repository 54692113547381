import React, { useMemo } from 'react';
import UsersTable from './UsersTable';
import MerchantDetailsForm from './forms/MerchantDetailsForm';

const STATUS_INGESTING = 'ingesting';
const STATUS_INGESTED = 'ingested';
const STATUS_SKIPPED = 'skipped';

const SummaryTab = ({
  submitting,
  error,
  merchant,
  initialValues,
  handleSubmit,
}) => {
  const canSetGoLive = useMemo(() => (
    merchant.onboardingStatus === STATUS_INGESTING
      || merchant.onboardingStatus === STATUS_INGESTED
      || merchant.onboardingStatus === STATUS_SKIPPED
  ), [merchant]);
  return (
    <div>
      <div className="row">
        <div className="col-md-6 col-xl-5">
          <div className="merch-card">
            <span className="merch-title">Company Details</span>
            <MerchantDetailsForm
              // @ts-ignore
              submitting={submitting}
              initialValues={initialValues}
              merchant={merchant}
              onSubmit={handleSubmit}
              subErr={error}
              canSetGoLive={canSetGoLive}
            />
          </div>
        </div>
        <div className="col-md-6 col-xl-7">
          <div className="merch-card">
            <span className="merch-title">Company Users</span>
            <UsersTable users={merchant.users} onClick={() => {}} />
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="merch-card">
                <span className="merch-title">Company Statistics</span>
                <span className="merch-stat">
                  ALR -
                  {' '}
                  {merchant.currencySymbol + (merchant.ALR || '0.00')}
                </span>
                <span className="merch-stat">
                  Avg. Reorder Rate -
                  {' '}
                  {`${((merchant.ARR || 0) * 100).toFixed(2)}%`}
                </span>
                <span className="merch-stat">
                  Avg. Reorder Frequency -
                  {' '}
                  {`${merchant.AARFdays || '0'} days`}
                </span>
                <span className="merch-stat">
                  Churn -
                  {' '}
                  {`${merchant.roryChurn || 0}%`}
                </span>
                <span className="merch-stat">
                  AOV -
                  {' '}
                  {merchant.currencySymbol
                    + (Number(merchant.AOV) || 0).toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryTab;
