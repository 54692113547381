import React, { useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { BlueprintAPI } from '../redux/actions/BlueprintAPI';

const invokeOperation = async (merchant, operation, addToast) => {
  const sessionToken = await localStorage.getItem('sToken');
  const payload = {
    sessionToken,
    merchantId: merchant.id,
    operation,
  };
  let response = null;
  try {
    response = await BlueprintAPI(
      'blueprint-admin-backend',
      'merchantOperation',
      payload,
    );
  } catch (e) {
    console.log(e);
  }
  if (response && response?.status === 200 && response?.data?.ok) {
    addToast(response.data.message, {
      appearance: 'success',
      autoDismiss: true,
    });
  } else {
    addToast('Operation failed', {
      appearance: 'error',
      autoDismiss: true,
    });
  }
};

export const OperationsTab = ({ loading, merchant }) => {
  const { addToast } = useToasts();
  const [regeneratingPredictions, setRegeneratingPredictions] = useState(false);
  const [shopifyHistoricLoadInProgress, setShopifyHistoricLoadInProgress] = useState(false);
  const [disablingMerchant, setDisablingMerchant] = useState(false);

  const handleUnsetGoLive = useCallback(async () => {
    setDisablingMerchant(true);

    await invokeOperation(merchant, 'DISABLE_MERCHANT', addToast);

    setDisablingMerchant(false);
  },
  [merchant, addToast]);

  const handleRecalculatePredictions = useCallback(async () => {
    setRegeneratingPredictions(true);

    await invokeOperation(merchant, 'REGENERATE_PREDICTIONS', addToast);
  },
  [merchant, addToast]);

  const handleRecalculatePredictionsForFirstTime = useCallback(async () => {
    setRegeneratingPredictions(true);

    await invokeOperation(merchant, 'REGENERATE_PREDICTIONS_FOR_FIRST_TIME', addToast);
  },
  [merchant, addToast]);

  const handleReImportShopify = useCallback(async () => {
    setShopifyHistoricLoadInProgress(true);

    await invokeOperation(merchant, 'RESTART_SHOPIFY_HISTORIC_LOAD', addToast);
  },
  [merchant, addToast]);

  if (loading) {
    return (
      <div>Loading....</div>
    );
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-12 col-xl-12">
          <div className="merch-card">
            <span className="merch-title">Operations</span>
            <p>
              <b>READ THESE BEFORE CLICKING ON ANYTHING!</b>
              <ul>
                <li>Shopify data re-import and prediction recalculations could take hours to complete</li>
                <li>
                  Do not run more than 4 prediction recalculations at a time
                  (that could slow down the process or cause database load issues)
                </li>
                <li>
                  Check #predictions and #merchant-notifications Slack channels
                  to see the status of jobs

                </li>
                <li>
                  Please note that you will not be be able re-click on the buttons once the
                  operation is started
                  (unless you reload the page)
                </li>
                <li>
                  About feature Generate predictions for first time:
                  <ul>
                    <li>
                      If you click this feature more than once, it will repeat
                      predictions already sent.
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
            <div>
              <button type="button" className="btn btn-primary" onClick={handleReImportShopify} disabled={shopifyHistoricLoadInProgress}>
                {shopifyHistoricLoadInProgress ? 'Re-importing...' : 'Re-import data'}
              </button>
              {' '}
              Trigger re-import of Shopify data
            </div>
            <br />
            <div>
              <button type="button" className="btn btn-primary" onClick={handleRecalculatePredictions} disabled={regeneratingPredictions}>
                { regeneratingPredictions ? 'Re-calculating...' : 'Re-calculate predictions' }
              </button>
              {' '}
              Re-calculate predictions for this
              {' '}
              <b>{ merchant.isV2Enabled ? 'V2' : 'V1' }</b>
              {' '}
              merchant
            </div>
            <br />
            <div>
              <button type="button" className="btn btn-warning" onClick={handleRecalculatePredictionsForFirstTime} disabled={regeneratingPredictions}>
                { regeneratingPredictions ? 'Generating...' : 'Generate predictions for FIRST TIME' }
              </button>
              {' '}
              Generate predictions for
              {' '}
              <strong>first time</strong>
              {' '}
              for this
              {' '}
              <b>{ merchant.isV2Enabled ? 'V2' : 'V1' }</b>
              {' '}
              merchant
            </div>
            <br />
            <div>
              <button type="button" className="btn btn-danger" onClick={handleUnsetGoLive} disabled={disablingMerchant}>
                { disablingMerchant ? 'Disabling' : 'Disable merchant' }
              </button>
              {' '}
              Remove the go-live date and disable SMS sending
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperationsTab;
