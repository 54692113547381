import React from 'react';

export const BPToggle = (props) => {
  const { input, disabled } = props;

  return (
    <div
      className={
        `bp-toggle ${
          input.checked ? 'active' : ''
        }${disabled ? ' disabled' : ''}`
      }
      onClick={
        disabled
          ? () => null
          : () => {
            input.onChange(!input.checked);
          }
      }
      role="switch"
      aria-checked={input.checked}
      tabIndex={0}
    >
      {' '}
      <div className="slider" />
      {' '}
    </div>
  );
};

export default BPToggle;
