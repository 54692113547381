import * as Sentry from '@sentry/react';
import { MerchantActionTypes } from '../../types';
import { BlueprintAPI, API, APIMethods } from '../BlueprintAPI';

const SERVICE = 'blueprint-admin-backend';

const MerchantActionCreators = {
  getAllMerchants: () => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.GET_MERCHANTS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem('sToken');
      const data = { sessionToken };
      const response = await BlueprintAPI(SERVICE, 'getAllMerchants', data);
      dispatch({
        type: MerchantActionTypes.GET_MERCHANTS_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: MerchantActionTypes.GET_MDATA_RES,
        error: e.data.message || 'Something went wrong',
      });
    }
  },
  getMerchantData: (merchantId) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.GET_MDATA_REQ,
    });
    try {
      const data = { merchantId };
      const response = await BlueprintAPI(SERVICE, 'getMerchantData', data, null, true, APIMethods.GET);
      dispatch({
        type: MerchantActionTypes.GET_MDATA_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: MerchantActionTypes.GET_MDATA_RES,
        error: e.data.message || 'Something went wrong',
      });
    }
  },
  updateMerchantData: (data) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.UPDATE_MDATA_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem('sToken');
      const payload = { sessionToken, ...data };
      const response = await BlueprintAPI(
        SERVICE,
        'updateMerchantData',
        payload,
      );
      dispatch({
        type: MerchantActionTypes.UPDATE_MDATA_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: MerchantActionTypes.UPDATE_MDATA_RES,
        error: e.data.message || 'Something went wrong',
      });
    }
  },
  createMerchant: (data) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.CREATE_MERCHANT_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem('sToken');
      const payload = { sessionToken, ...data };
      const response = await BlueprintAPI(SERVICE, 'createMerchant', payload);
      dispatch({
        type: MerchantActionTypes.CREATE_MERCHANT_RES,
        payload: response.data,
      });
    } catch (e) {
      console.error('error while creating a merchant', e);
      dispatch({
        type: MerchantActionTypes.CREATE_MERCHANT_RES,
        error: e.data.message || 'Something went wrong',
      });
      Sentry.captureException(e);
    }
  },
  deleteMerchantUser: (data) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.DELETE_MERCH_USER_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem('sToken');
      const payload = { sessionToken, ...data };
      const response = await BlueprintAPI(
        SERVICE,
        'deleteMerchantUser',
        payload,
      );
      dispatch({
        type: MerchantActionTypes.DELETE_MERCH_USER_RES,
        payload: response.data,
      });
    } catch (e) {
      console.error('error while deleting a merchant user', e);
      dispatch({
        type: MerchantActionTypes.DELETE_MERCH_USER_RES,
        error: e.data.message || 'Something went wrong',
      });
      Sentry.captureException(e);
    }
  },
  saveMerchantUser: (data) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.SAVE_MERCH_USER_REQ,
    });
    try {
      const { id } = data;
      const endpoint = id ? 'updateMerchantUser' : 'createMerchantUser';
      const sessionToken = await localStorage.getItem('sToken');
      const payload = { sessionToken, ...data };
      const response = await BlueprintAPI(SERVICE, endpoint, payload);
      dispatch({
        type: MerchantActionTypes.SAVE_MERCH_USER_RES,
        payload: response.data,
      });
    } catch (e) {
      console.error('error while updating a merchant user', e);
      dispatch({
        type: MerchantActionTypes.SAVE_MERCH_USER_RES,
        error: e.data.message || 'Something went wrong',
      });
      Sentry.captureException(e);
    }
  },
  updateMerchantApp: (data) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.UPDATE_MERCH_APP_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem('sToken');
      const payload = { sessionToken, ...data };
      const response = await BlueprintAPI(
        SERVICE,
        'updateMerchantApp',
        payload,
      );
      dispatch({
        type: MerchantActionTypes.UPDATE_MERCH_APP_RES,
        payload: response.data,
      });
    } catch (e) {
      console.error('error while updating a merchant app', e);
      dispatch({
        type: MerchantActionTypes.UPDATE_MERCH_APP_RES,
        error: e.data.message || 'Something went wrong',
      });
      Sentry.captureException(e);
    }
  },
  createStripeSubscription: (
    merchantId,
    stripePlanId,
    paymentMethodId,
  ) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.CREATE_SUB_REQ,
    });

    try {
      const sessionToken = await localStorage.getItem('sToken');
      const customer = await BlueprintAPI(SERVICE, 'createStripeCustomer', {
        sessionToken,
        merchantId,
      });
      const { stripeCustomerId } = customer.data;
      const subscription = await BlueprintAPI(SERVICE, 'createSubscription', {
        sessionToken,
        merchantId,
        stripeCustomerId,
        stripePlanId,
        paymentMethodId,
      });
      const { subscriptionItemId } = subscription.data;
      dispatch({
        type: MerchantActionTypes.CREATE_SUB_RES,
        payload: { subscriptionItemId },
      });
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: MerchantActionTypes.CREATE_SUB_RES,
        error: e.data.message || 'Something went wrong',
      });
    }
  },
  updateAutoresponder: (data) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.SAVE_AUTORESPONDER_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem('sToken');
      const payload = { sessionToken, ...data };

      const response = await BlueprintAPI(
        SERVICE,
        'updateAutoresponder',
        payload,
      );
      dispatch({
        type: MerchantActionTypes.SAVE_AUTORESPONDER_RES,
        payload: response.data,
      });
    } catch (e) {
      console.error('error while saving an autoresponder', e);
      dispatch({
        type: MerchantActionTypes.SAVE_AUTORESPONDER_RES,
        error: e.data.message || 'Something went wrong',
      });
      Sentry.captureException(e);
    }
  },
  createAutoresponder: (data) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.CREATE_AUTORESPONDER_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem('sToken');
      const payload = { sessionToken, ...data };

      const response = await BlueprintAPI(
        SERVICE,
        'createAutoresponder',
        payload,
      );
      dispatch({
        type: MerchantActionTypes.CREATE_AUTORESPONDER_RES,
        payload: response.data,
      });
    } catch (e) {
      console.error('error while saving an autoresponder', e);
      dispatch({
        type: MerchantActionTypes.CREATE_AUTORESPONDER_RES,
        error: e.data.message || 'Something went wrong',
      });
      Sentry.captureException(e);
    }
  },
  /**
   * @param {import('axios').AxiosRequestConfig} options
   * @returns
   */
  uploadNumbers: (formData, options) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.UPLOAD_NUMBERS_REQ,
    });
    try {
      const response = await API(
        'post',
        SERVICE,
        'upload/customers',
        null,
        formData,
        undefined,
        undefined,
        options,
      );

      dispatch({
        type: MerchantActionTypes.UPLOAD_NUMBERS_RES,
        payload: response.data,
      });
    } catch (e) {
      console.error('error while uploading the numbers', e);
      dispatch({
        type: MerchantActionTypes.UPLOAD_NUMBERS_RES,
        error: e.data.message || 'Something went wrong',
      });
      Sentry.captureException(e);
    }
  },
  resetState: () => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.RESET_STATE,
    });
  },
  updateSettings: (data) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.UPDATE_MERCH_SETTINGS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem('sToken');
      const payload = { sessionToken, ...data };
      const response = await BlueprintAPI(
        SERVICE,
        'updateMerchantSettings',
        payload,
      );
      dispatch({
        type: MerchantActionTypes.UPDATE_MERCH_SETTINGS_RES,
        payload: response.data,
      });
    } catch (e) {
      console.error('error while updating a merchants settings', e);
      dispatch({
        type: MerchantActionTypes.UPDATE_MERCH_SETTINGS_RES,
        error: e?.data?.message || 'Something went wrong',
      });
      Sentry.captureException(e);
    }
  },
  getPostOrderFeedbackData: (offset = 0, limit = 1) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.GET_POST_ORDER_DATA_REQ,
    });
    try {
      const data = { offset, limit };
      const response = await BlueprintAPI(
        SERVICE,
        'getPostOrderFeedback',
        data,
        null,
        true,
        APIMethods.GET,
      );
      dispatch({
        type: MerchantActionTypes.GET_POST_ORDER_DATA_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: MerchantActionTypes.GET_POST_ORDER_DATA_RES,
        error: e?.data?.message || 'Something went wrong',
      });
    }
  },
  sendAction: (data, action) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.SEND_FEEDBACK_ACTION_REQ,
    });
    try {
      await BlueprintAPI(
        SERVICE,
        action,
        data,
      );
      dispatch({
        type: MerchantActionTypes.SEND_FEEDBACK_ACTION_RES,
      });
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: MerchantActionTypes.SEND_FEEDBACK_ACTION_RES,
        error: e?.data?.message || 'Something went wrong',
      });
    }
  },
  setTopics: (data) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.SET_TOPICS_REQ,
    });
    try {
      await BlueprintAPI(
        SERVICE,
        'setTopics',
        data,
      );
      dispatch({
        type: MerchantActionTypes.SET_TOPICS_RES,
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: MerchantActionTypes.SET_TOPICS_RES,
        error: e?.data?.message || 'Something went wrong',
      });
    }
  },
};

export default MerchantActionCreators;
