export const AuthActionTypes = {
  AUTH_STATUS: 'AUTH_STATUS',
  LOGIN_REQ: 'LOGIN_REQ',
  LOGIN_RES: 'LOGIN_RES',
};

export const MerchantActionTypes = {
  GET_MERCHANTS_REQ: 'GET_MERCHANTS_REQ',
  GET_MERCHANTS_RES: 'GET_MERCHANTS_RES',
  GET_MDATA_REQ: 'GET_MDATA_REQ',
  GET_MDATA_RES: 'GET_MDATA_RES',
  GET_POST_ORDER_DATA_REQ: 'GET_POST_ORDER_DATA_REQ',
  GET_POST_ORDER_DATA_RES: 'GET_POST_ORDER_DATA_RES',
  UPDATE_MDATA_REQ: 'UPDATE_MDATA_REQ',
  UPDATE_MDATA_RES: 'UPDATE_MDATA_RES',
  CREATE_SUB_REQ: 'CREATE_SUB_REQ',
  CREATE_SUB_RES: 'CREATE_SUB_RES',
  CREATE_MERCHANT_REQ: 'CREATE_MERCHANT_REQ',
  CREATE_MERCHANT_RES: 'CREATE_MERCHANT_RES',
  UPDATE_MERCH_APP_REQ: 'UPDATE_MERCH_APP_REQ',
  UPDATE_MERCH_APP_RES: 'UPDATE_MERCH_APP_RES',
  SAVE_MERCH_USER_REQ: 'SAVE_MERCH_USER_REQ',
  SAVE_MERCH_USER_RES: 'SAVE_MERCH_USER_RES',
  DELETE_MERCH_USER_REQ: 'DELETE_MERCH_USER_REQ',
  DELETE_MERCH_USER_RES: 'DELETE_MERCH_USER_RES',
  LOAD_AUTORESPONDER_REQ: 'LOAD_AUTORESPONDER_REQ',
  LOAD_AUTORESPONDER_RES: 'LOAD_AUTORESPONDER_RES',
  SAVE_AUTORESPONDER_REQ: 'SAVE_AUTORESPONDER_REQ',
  SAVE_AUTORESPONDER_RES: 'SAVE_AUTORESPONDER_RES',
  CREATE_AUTORESPONDER_REQ: 'CREATE_AUTORESPONDER_REQ',
  CREATE_AUTORESPONDER_RES: 'CREATE_AUTORESPONDER_RES',
  UPLOAD_NUMBERS_REQ: 'UPLOAD_NUMBERS_REQ',
  UPLOAD_NUMBERS_RES: 'UPLOAD_NUMBERS_RES',
  UPDATE_MERCH_SETTINGS_REQ: 'UPDATE_MERCH_SETTINGS_REQ',
  UPDATE_MERCH_SETTINGS_RES: 'UPDATE_MERCH_SETTINGS_RES',
  SEND_FEEDBACK_ACTION_REQ: 'SEND_FEEDBACK_ACTION_REQ',
  SEND_FEEDBACK_ACTION_RES: 'SEND_FEEDBACK_ACTION_RES',
  SET_TOPICS_REQ: 'SET_TOPICS_REQ',
  SET_TOPICS_RES: 'SET_TOPICS_RES',
  RESET_STATE: 'RESET_STATE',
};

export const CustomerActionTypes = {
  GET_CUS_REQ: 'GET_CUS_REQ',
  GET_CUS_RES: 'GET_CUS_RES',
  ADD_CUSNOTE_REQ: 'ADD_CUSNOTE_REQ',
  ADD_CUSNOTE_RES: 'ADD_CUSNOTE_RES',
};
