import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { AuthReducer } from './auth';
import { MerchantReducer } from './merchant';
import { CustomerReducer } from './customer';

/** @typedef {ReturnType<typeof reducers>} RootState */
const reducers = combineReducers({
  form: formReducer,
  AuthReducer,
  MerchantReducer,
  CustomerReducer,
});

export default reducers;
