import React from 'react';
import { Form, Field } from 'react-final-form';
import BPField from './BPField';
import { shopifyAppValidate } from '../../utils/validation';

export const ShopifyAppForm = ({ initialValues, loading, onSubmit }) => (
  <Form
    validate={shopifyAppValidate}
    onSubmit={onSubmit}
    initialValues={initialValues}
    render={({
      handleSubmit,
      submitError,
      submitting,
      form,
      pristine,
      valid,
    }) => (
      <form onSubmit={handleSubmit} className="merchant-details-form">
        <div className="form-group brand-color">
          <label htmlFor="storeDomain">Store Domain</label>
          <div className="field">
            <Field
              name="storeDomain"
              component={BPField}
              type="storeDomain"
              placeholder="Store Domain"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group brand-color">
          <label htmlFor="apiKey">API Key</label>
          <div className="field">
            <Field
              name="apiKey"
              component={BPField}
              type="text"
              placeholder="API Key"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group brand-color">
          <label htmlFor="apiSecret">API Secret</label>
          <div className="field">
            <Field
              name="apiSecret"
              component={BPField}
              type="text"
              placeholder="API Secret"
              className="form-control"
            />
          </div>
        </div>
        {submitError && <span className="form-error">{submitError}</span>}
        <div className="form-group buttons">
          <button
            type="submit"
            disabled={!valid || submitting || pristine}
            className="btn btn-primary"
          >
            {loading ? 'Saving...' : 'Save Changes'}
          </button>
          {!pristine && (
          <button
            type="button"
            onClick={form.reset}
            className="btn btn-link"
          >
            Cancel
          </button>
          )}
        </div>
      </form>
    )}
  />
);

export default ShopifyAppForm;
