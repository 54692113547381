import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import Reducers from './reducers';

// Allow REACT_APP_BLUEPRINT_API_URL to be overridden by localStorage
/** @type {HTMLDivElement?} */
let baseLabel = null;
window.blueprintGetApiBaseURL = () => {
  // This is a WIP, needs tidy
  // Only allow overrides if dev instance
  if (
    process.env.REACT_APP_BLUEPRINT_API_URL === 'https://dev.blueprint-api.com'
  ) {
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamsBaseAPIURL = urlParams.get('baseApiURL');
    if (urlParamsBaseAPIURL && urlParamsBaseAPIURL !== '') {
      localStorage.setItem('baseApiURL', urlParamsBaseAPIURL);
    }
    if (localStorage.getItem('baseApiURL')) {
      if (!baseLabel) {
        baseLabel = document.createElement('div');
        if (!baseLabel) {
          throw new Error('Failed to create baseLabel element');
        }
        // @ts-ignore
        baseLabel.style = 'position:fixed;background:orange;padding:3px 8px;border:1px solid red;right:0px;bottom:0px;color:red;';
        baseLabel.innerText = localStorage.getItem('baseApiURL') || '';
        document.body.appendChild(baseLabel);
      }
      return localStorage.getItem('baseApiURL');
    }
  }
  return process.env.REACT_APP_BLUEPRINT_API_URL;
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  Reducers,
  undefined,
  composeEnhancers(applyMiddleware(thunk)),
);

export default store;
