import React from 'react';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Logo from './Logo';
import MerchantActionCreators from '../../redux/actions/merchant';

function Header() {
  return (
    <header className="a-header">
      <NavLink to="/">
        <Logo small />
      </NavLink>
    </header>
  );
}

const mapStateToProps = (state) => ({
  userData: state.MerchantReducer.userData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(MerchantActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
