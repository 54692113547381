import React, { Component } from 'react';
import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AuthActionCreators from '../redux/actions/auth';
import Header from './global/Header';
import Dashboard from '../Pages/Dashboard';
import Logout from '../Pages/Logout';
import Login from '../Pages/Login';
import Merchants from '../Pages/Merchants';
import { MerchantBilling } from '../Pages/MerchantBilling';
import Merchant from '../Pages/Merchant';
import PostOrderFeedback from '../Pages/PostOrderFeedback';

const ProtectedRoute = ({ isVerified, li = undefined, ...props }) => (isVerified ? (
  <Route {...props} />
) : (
  <Redirect to={li ? '/' : `/login?return_url=${props.path}`} />
));

class Routing extends Component {
  constructor(props) {
    super(props);

    this.state = { canRender: false };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { actions } = this.props;
    actions
      .checkToken()
      .then(() => this.setState({ canRender: true }))
      .catch(() => this.setState({ canRender: true }));
  }

  render() {
    const { canRender } = this.state;
    const { isAuthed } = this.props;
    if (canRender) {
      return (
        <BrowserRouter>
          <ToastProvider>
            <Header />
            <Switch>
              <Route exact path="/login" component={Login} />
              <ProtectedRoute
                isVerified={isAuthed}
                exact
                path="/"
                component={Dashboard}
              />
              <ProtectedRoute
                isVerified={isAuthed}
                exact
                path="/merchants"
                component={Merchants}
              />
              <ProtectedRoute
                isVerified={isAuthed}
                exact
                path="/merchants/:merchantId"
                component={Merchant}
              />
              <ProtectedRoute
                isVerified={isAuthed}
                exact
                path="/merchants/:merchantId/billing/:stripePlanId"
                component={MerchantBilling}
              />
              <ProtectedRoute
                isVerified={isAuthed}
                exact
                path="/feedback/:offset?"
                component={PostOrderFeedback}
              />
              <ProtectedRoute
                isVerified={isAuthed}
                exact
                path="/logout"
                component={Logout}
              />
              <Redirect to="/" />
            </Switch>
          </ToastProvider>
        </BrowserRouter>
      );
    }
    return <div className="container">Loading...</div>;
  }
}

const mapStateToProps = (state) => ({
  isAuthed: state.AuthReducer.isAuthed,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(AuthActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
