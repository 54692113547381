import React from 'react';

/**
 * @typedef AutoresponderEntry
 * @property {Number} id
 * @property {Boolean} isActive
 * @property {String} triggerText
 * @property {String} messageText
 * @property {String} link
 */

/**
 * @param {Object} props
 * @param {AutoresponderEntry} props.app
 * @param {Function} props.onClick
 * @param {Boolean} props.isSelected
 */
const Row = ({ app, onClick, isSelected }) => (
  <div
    className={`line row ${isSelected ? 'selected' : ''}`}
    onClick={() => onClick(app)}
    role="button"
    tabIndex={0}
  >
    <div className="col-md-1">{app.id}</div>
    <div className="col-md-1">{app.isActive ? 'yes' : 'no'}</div>
    <div className="col-md-2">{app.triggerText}</div>
    <div className="col-md-4">{app.messageText}</div>
    <div className="col-md-4">
      <a href={app.link} target="_blank" rel="noreferrer">
        {app.link}
      </a>
    </div>
  </div>
);

/**
 * @param {Object} props
 * @param {AutoresponderEntry[]} props.data
 * @param {AutoresponderEntry?} props.selected
 * @param {Function} props.onClick
 */
export const AutorespondersTable = ({ data, selected, onClick }) => (
  <div className="users-table bp-table">
    <div className="t-header row">
      <div className="col-md-1">Id</div>
      <div className="col-md-1">Active</div>
      <div className="col-md-2">Trigger</div>
      <div className="col-md-4">Response</div>
      <div className="col-md-4">Link</div>
    </div>
    <div className="t-body">
      {data && data.length > 0 ? (
        data.map((el) => (
          <Row
            key={el.id}
            app={el}
            onClick={onClick}
            isSelected={el.id === selected?.id}
          />
        ))
      ) : (
        <div className="line row">
          <div className="col text-center">No data</div>
          {' '}
        </div>
      )}
    </div>
  </div>
);

export default AutorespondersTable;
