import React from 'react';
import MerchantCreationForm from './forms/MerchantCreationForm';

const DUMMY_PASSWORD = 'DUMMY_PASSWORD';

export const InstallationTab = ({ merchant }) => {
  const [shopifyApp] = merchant.shopifyApps;
  const [initialUser] = merchant.users;

  const defaults = {
    storeCurrency: merchant.currency,
    storeEcomType: merchant.platform,
    storeCustomDomain: merchant.websiteUrl,
    storeName: merchant.company,
    storePhone: merchant.phone,
    initialUserEmail: initialUser ? initialUser.email : '',
    initialUsername: initialUser ? initialUser.name : '',
    initialUserPassword: DUMMY_PASSWORD,
    shopifyDomain: shopifyApp ? shopifyApp.storeDomain : '',
    shopifyAppKey: shopifyApp ? shopifyApp.apiKey : '',
    shopifyAppSecret: DUMMY_PASSWORD,
  };

  return (
    <div>
      <MerchantCreationForm readOnly defaults={defaults} />
    </div>
  );
};

export default InstallationTab;
