import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router-dom';
import PageBuilder from '../components/global/PageBuilder';
import BTitle from '../components/global/BTitle';
import MerchantActionCreators from '../redux/actions/merchant';

import { ShopifyAppsTab } from '../components/ShopifyAppsTab';
import AutorespondersTab from '../components/AutorespondersTab';
import { OperationsTab } from '../components/OperationsTab';
import { InstallationTab } from '../components/InstallationTab';
import SummaryTab from '../components/SummaryTab';
import UsersTab from '../components/UsersTab';
import { BPTabs } from '../components/BPTabs';
import { SettingsTab } from '../components/SettingsTab';
import { formatDate, FORMAT_YEAR_MONTH_DAY_HOUR_MIN_SEC } from '../utils/dates';
import UploadPhoneNumbersTab from '../components/UploadPhoneNumbersTab';

const {
  getMerchantData: getMerchantDataAction,
  saveMerchantUser: saveMerchantUserAction,
  updateMerchantData: updateMerchantDataAction,
  updateMerchantApp: updateMerchantAppAction,
  deleteMerchantUser: deleteMerchantUserAction,
  updateAutoresponder: updateAutoresponderAction,
  createAutoresponder: createAutoresponderAction,
  uploadNumbers: uploadNumbersAction,
  resetState: resetStateAction,
  updateSettings: updateSettingsAction,
} = MerchantActionCreators;

const Merchant = () => {
  const { merchantId } = useParams();
  const {
    merchantErr,
    merchantData,
    loading,
    merchantUpdated: updated,
    merchantLoaded: loaded,
    merchantUserUpdated: userUpdated,
    merchantAppUpdated: appUpdated,
    autorespondersUpdated,
    uploadedNumbers,
    updatingSettings,
    updateSettingsError,
    updateSettingsResult,
  } = useSelector((/** @type {import('../redux/reducers').RootState} */state) => state.MerchantReducer);

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  useEffect(() => {
    dispatch(getMerchantDataAction(merchantId));
  }, [dispatch, merchantId]);

  useEffect(() => {
    if (
      (updated || appUpdated || userUpdated)
      && merchantData.id === Number(merchantId)
    ) {
      dispatch(getMerchantDataAction(merchantId));
      addToast('Saved successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
    }
  }, [
    updated,
    appUpdated,
    userUpdated,
    addToast,
    merchantData.id,
    merchantId,
    dispatch,
  ]);

  useEffect(() => {
    if (autorespondersUpdated && merchantData.id === Number(merchantId)) {
      addToast('Autoresponder saved successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
    }
  }, [autorespondersUpdated, addToast, merchantData.id, merchantId]);

  const initialValues = useMemo(() => {
    if (merchantData && loaded) {
      return {
        company: merchantData.company,
        phone: merchantData.phone,
        goLiveDate: merchantData.goLiveDate
          ? formatDate(
            merchantData.goLiveDate,
            FORMAT_YEAR_MONTH_DAY_HOUR_MIN_SEC,
          )
          : null,
        hubspotDealId: merchantData.hubspotDealId,
      };
    }
    return {};
  }, [loaded, merchantData]);

  useEffect(() => {
    if (merchantErr) {
      const toastErrorMessage = merchantErr ? `Something went wrong: ${merchantErr}` : 'Something went wrong';
      addToast(toastErrorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, merchantErr]);

  useEffect(() => {
    if (updateSettingsError) {
      addToast(updateSettingsError, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, updateSettingsError]);

  useEffect(() => {
    if (updateSettingsResult) {
      addToast(updateSettingsResult, {
        appearance: 'info',
        autoDismiss: true,
      });
    }
  }, [addToast, updateSettingsResult]);

  const handleSubmit = async (data) => {
    if (!loading) {
      const goLiveDate = data.goLiveDate === '' ? null : data.goLiveDate;
      const payload = { ...data, merchantId: merchantData.id, goLiveDate };
      dispatch(updateMerchantDataAction(payload));
    }
  };

  const handleUserSubmit = async (data) => {
    if (!loading) {
      const { id, ...user } = data;
      const payload = id
        ? {
          ...user,
          id,
          pw: user.pw || '',
          merchantId: merchantData.id,
        }
        : {
          ...user,
          merchantId: merchantData.id,
        };
      dispatch(saveMerchantUserAction(payload));
    }
  };

  const handleAppSubmit = async ({
    id, storeDomain, apiKey, apiSecret,
  }) => {
    if (!loading) {
      dispatch(
        updateMerchantAppAction({
          id,
          storeDomain,
          apiKey,
          apiSecret,
        }),
      );
    }
  };

  const handleAutoresponderEdit = async (data) => {
    if (!loading) {
      dispatch(updateAutoresponderAction(data));
    }
  };

  const handleAutoresponderCreate = async (data) => {
    if (!loading) {
      dispatch(createAutoresponderAction(data));
    }
  };

  /**
 * @param {import('axios').AxiosRequestConfig} options
 * @returns
 */
  const handleUploadNumbers = async (data, options) => {
    if (!loading) {
      dispatch(uploadNumbersAction(data, options));
    }
  };

  const handleResetState = async () => {
    dispatch(resetStateAction());
  };

  const handleDeleteUser = useCallback(
    async (id) => {
      if (!loading) {
        await dispatch(
          deleteMerchantUserAction({
            merchantId: merchantData.id,
            id,
          }),
        );
      }
    },
    [dispatch, loading, merchantData.id],
  );

  const handleSettingsEdit = async (data) => {
    if (!loading) {
      dispatch(updateSettingsAction({ ...data, merchantId: merchantData.id }));
    }
  };

  const canRender = (loaded || updated) && Object.keys(merchantData).length > 0;

  return (
    <PageBuilder>
      <div className="a-view merchant">
        <div className="container">
          <BTitle text={canRender ? merchantData.company : 'Loading..'} />
          {canRender && (
            <BPTabs
              tabs={[
                {
                  key: 'Summary',
                  title: 'Summary',
                  content: (
                    <SummaryTab
                      merchant={merchantData}
                      submitting={loading}
                      error={merchantErr}
                      initialValues={initialValues}
                      handleSubmit={handleSubmit}
                    />
                  ),
                },
                {
                  key: 'Users',
                  title: 'Users',
                  content: (
                    <UsersTab
                      loading={loading}
                      merchant={merchantData}
                      handleSubmit={handleUserSubmit}
                      handleDeleteUser={handleDeleteUser}
                    />
                  ),
                },
                {
                  key: 'Shopify Apps',
                  title: 'Shopify Apps',
                  content: (
                    <ShopifyAppsTab
                      loading={loading}
                      merchant={merchantData}
                      handleSubmit={handleAppSubmit}
                    />
                  ),
                },
                {
                  key: 'Smartlinks',
                  title: 'Smartlinks',
                  content: (
                    <AutorespondersTab
                      merchantId={merchantData.id}
                      autoresponders={merchantData.autoresponders}
                      autoresponderUrl={merchantData.autoresponderUrl}
                      handleEdit={handleAutoresponderEdit}
                      handleCreate={handleAutoresponderCreate}
                    />
                  ),
                },
                {
                  key: 'Install Info',
                  title: 'Install Info',
                  content: (
                    <InstallationTab
                      merchant={merchantData}
                    />
                  ),
                },
                {
                  key: 'Upload',
                  title: 'Upload',
                  content: (
                    <UploadPhoneNumbersTab
                      error={merchantErr}
                      loading={loading}
                      merchant={merchantData}
                      onLeave={handleResetState}
                      onUpload={handleUploadNumbers}
                      uploadResult={uploadedNumbers}
                    />
                  ),
                },
                {
                  key: 'Settings',
                  title: 'Settings',
                  content: (
                    <SettingsTab
                      loading={updatingSettings}
                      merchant={merchantData}
                      handleSubmit={handleSettingsEdit}
                    />
                  ),
                },
                {
                  key: 'Operations',
                  title: 'Operations',
                  content: (
                    <OperationsTab
                      loading={updatingSettings}
                      merchant={merchantData}
                    />
                  ),
                },
              ]}
            />
          )}
        </div>
      </div>
    </PageBuilder>
  );
};

export default Merchant;
