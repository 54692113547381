import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import camelcase from 'camelcase';
import BPField from './BPField';
import { brandDetailsValidate } from '../../utils/validation';

export function makeConfigCatKey(str) {
  // Forcing a replacement to "-" gives camelcase() a new word boundary
  const alphaNumericReplacement = str.replace(/\W/g, '-');

  return camelcase(alphaNumericReplacement);
}

class MerchantDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = { initialValues: null };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { initialValues } = this.props;
    this.setState({ initialValues });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { initialValues } = this.state;
    const { initialize } = this.props;
    if (
      JSON.stringify(nextProps.initialValues)
      !== JSON.stringify(initialValues)
    ) {
      this.setState({ initialValues: nextProps.initialValues });
      initialize(nextProps.initialValues);
    }
  }

  render() {
    const {
      merchant,
      handleSubmit,
      valid,
      subErr,
      submitting,
      loading,
      reset,
      pristine,
      canSetGoLive,
    } = this.props;
    return (
      <form onSubmit={handleSubmit} className="merchant-details-form">
        <div className="form-group brand-color small">
          <label htmlFor="company">Company Name</label>
          <div className="field">
            <Field
              name="company"
              component={BPField}
              type="text"
              placeholder="Company Name"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group brand-color small">
          <label htmlFor="configCatKey">ConfigCat Key</label>
          <div className="field">
            <input
              id="configCatKey"
              className="form-control"
              type="text"
              disabled
              value={makeConfigCatKey(`${merchant.company}${merchant.id}`)}
            />
          </div>
        </div>
        <div className="form-group brand-color small">
          <label htmlFor="phone">Phone</label>
          <div className="field">
            <Field
              name="phone"
              component={BPField}
              type="text"
              placeholder="Phone"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group brand-logo small">
          <label htmlFor="goLiveDate">Go Live Date</label>
          <div className="field">
            <Field
              disabled={!canSetGoLive}
              name="goLiveDate"
              component={BPField}
              type="text"
              placeholder="YYYY-MM-DD HH:MM:SS"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group small">
          <label htmlFor="hubspotDealId">Hubspot Deal ID</label>
          <div className="field">
            <Field
              name="hubspotDealId"
              component={BPField}
              type="text"
              placeholder=""
              className="form-control"
            />
          </div>
        </div>
        {subErr && <span className="form-error">{subErr}</span>}
        <div className="form-group buttons">
          <button
            type="submit"
            disabled={!valid || submitting || pristine}
            className="btn btn-primary"
          >
            {loading ? 'Saving...' : 'Save Changes'}
          </button>
          {!pristine && (
            <button type="button" onClick={reset} className="btn btn-link">
              Cancel
            </button>
          )}
        </div>
      </form>
    );
  }
}

const MerchantDetailsFormWithForm = reduxForm({
  form: 'brandDetailsForm',
  validate: brandDetailsValidate,
})(MerchantDetailsForm);

export default connect(() => {})(MerchantDetailsFormWithForm);
