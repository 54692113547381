import React from 'react';
import { NavLink } from 'react-router-dom';
import BImage from './BImage';

const SidebarItems = [
  { link: '/', icon: 'bp-home', text: 'Home' },
  { link: '/merchants', icon: 'bp-groups', text: 'Merchants' },
  /* {link: "/analytics", icon: "bp-analytics", text: "Analytics"},
    {link: "/automated-messages", icon: "bp-automated-messages", text: "Automated Messages"},
    {link: "/abandoned-carts", icon: "bp-abandon-cart", text: "Abandoned Carts"}, */
  {
    link: '/settings', icon: 'bp-settings', text: 'Settings', disabled: true,
  },
  {
    link: '/feedback', icon: 'bp-analytics', text: 'Feedback',
  },
  { link: '/logout', icon: 'bp-logout', text: 'Logout' },
];

const Sidebar = () => (
  <div className="sidebar">
    {SidebarItems.map((item) => (
      <SidebarItem key={item.link} {...item} />
    ))}
  </div>
);

/**
 * @param {Object} props
 * @param {String} props.link
 * @param {Boolean} [props.disabled]
 * @param {String} props.text
 * @param {String} props.icon
 */
const SidebarItem = ({
  link, disabled, text, icon,
}) => (
  <NavLink
    exact
    to={link}
    className={`sidebar-link${disabled ? ' disabled' : ''}`}
    title={disabled ? '⏳ Coming Soon' : text}
  >
    <div>
      <BImage
        src={
            `https://static.blueprint.store/illustrations/dashboard/${
              icon
            }.svg`
          }
        alt={text}
      />
    </div>
    <span>{text}</span>
  </NavLink>
);

export default Sidebar;
