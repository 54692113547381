import React, {
  memo, useState, useEffect, useCallback,
} from 'react';
import { AutorespondersTable } from './AutorespondersTable';
import { AutoresponderForm, TYPE_CREATE } from './forms/AutoresponderForm';

/** @typedef {import('./AutorespondersTable').AutoresponderEntry} AutoresponderEntry */

const AutorespondersTab = ({
  merchantId,
  autoresponders,
  autoresponderUrl,
  handleEdit,
  handleCreate,
}) => {
  const [entry, setEntry] = useState(/** @type {AutoresponderEntry?} */ (null));
  const [isSaving, setIsSaving] = useState(false);

  const onClick = (selected) => {
    setEntry(selected);
  };

  useEffect(() => {
    setIsSaving(false);
    setEntry(null);
  }, [merchantId, autoresponders]);

  const onEdit = useCallback(
    (data) => {
      setIsSaving(true);
      handleEdit(data);
    },
    [handleEdit],
  );

  const onCreate = useCallback(
    (data) => {
      setIsSaving(true);
      setEntry(null);
      handleCreate(data);
    },
    [handleCreate],
  );

  return (
    <div>
      <div className="row">
        <div className="col-md-8 col-xl-8">
          <div className="merch-card">
            <span className="merch-title">Smartlinks</span>
            <AutorespondersTable
              data={autoresponders.map((el) => ({
                ...el,
                link: `${autoresponderUrl}/${el.linkCode}`,
              }))}
              onClick={onClick}
              selected={entry}
            />
          </div>
        </div>
        <div className="col-md-4 col-xl-4">
          {isSaving && <p>Saving...</p>}
          {!isSaving && entry && (
          <>
            <div className="merch-card">
              <span className="merch-title">
                Edit Smartlink
                {' '}
                {entry.id}
              </span>
              <AutoresponderForm
                initialValues={{ ...entry, merchantId }}
                onSubmit={onEdit}
                loading={isSaving}
              />
            </div>
            <div className="form-group buttons">
              <button
                className="btn btn-light btn-block"
                onClick={() => setEntry(null)}
                type="button"
              >
                Create New Smartlink
              </button>
            </div>
          </>
          )}
          {!isSaving && !entry && (
          <div className="merch-card">
            <span className="merch-title">Add New Smartlink</span>
            <AutoresponderForm
              initialValues={{ isActive: 1, merchantId }}
              type={TYPE_CREATE}
              onSubmit={onCreate}
              loading={isSaving}
            />
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(AutorespondersTab);
