import React from 'react';
import SettingsForm from './forms/SettingsForm';

export const SettingsTab = ({ loading, merchant, handleSubmit }) => (
  <div>
    <div className="row">
      <div className="col-md-12 col-xl-12">
        <div className="merch-card">
          <span className="merch-title">Settings</span>
          <SettingsForm loading={loading} initialValues={merchant} onSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  </div>
);

export default SettingsTab;
