import React from 'react';

const User = ({ user, onClick, index }) => (
  <div className="merchant-line row" role="button" tabIndex={index} onClick={() => onClick(user)}>
    <div className="col-md-1">{user.user.userId}</div>
    <div className="col-md-4">{user.user.name}</div>
    <div className="col-md-5">{user.user.email}</div>
    <div className="col-md-2">
      {user.role}
      {user.user.isDisabled ? ' (disabled)' : ''}
    </div>
  </div>
);

const UsersTable = ({ users, onClick }) => (
  <div className="users-table bp-table">
    <div className="t-header row">
      <div className="col-md-1">Id</div>
      <div className="col-md-4">Name</div>
      <div className="col-md-5">Email</div>
      <div className="col-md-2">Role</div>
    </div>
    <div className="t-body">
      {users.length > 0 ? (
        users.map((user, index) => (
          <User key={user.userId} user={user} onClick={onClick} index={index} />
        ))
      ) : (
        <div className="merchant-line row">
          <div className="col text-center">No data</div>
          {' '}
        </div>
      )}
    </div>
  </div>
);

export default UsersTable;
