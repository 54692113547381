import React, { useEffect, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import MerchantActionCreators from '../redux/actions/merchant';
import Loading from '../components/Loading';
import PageBuilder from '../components/global/PageBuilder';
import BTitle from '../components/global/BTitle';
import { roundToTwoDecimals } from '../utils';
import { formatDate, FORMAT_YEAR_MONTH_DAY_HOUR_MIN_SEC } from '../utils/dates';

const PAGINATION_LIMIT = 10;

const TOPICS = {
  DELIVERY_ISSUE: 'DELIVERY_ISSUE',
  DISLIKED_PRODUCT: 'DISLIKED_PRODUCT',
  OPTED_OUT: 'OPTED_OUT',
  PRODUCT_NOT_YET_USED: 'PRODUCT_NOT_YET_USED',
  REPURCHASE_INTENT: 'REPURCHASE_INTENT',
};

const TOPIC_OPTIONS = [
  { value: TOPICS.DELIVERY_ISSUE, label: 'Where is my order? / Issue with delivery' },
  { value: TOPICS.DISLIKED_PRODUCT, label: 'Didn’t like product' },
  { value: TOPICS.OPTED_OUT, label: 'Asked to stop messaging / Opt-out' },
  { value: TOPICS.PRODUCT_NOT_YET_USED, label: 'Hasn\'t tried / used product yet' },
  { value: TOPICS.REPURCHASE_INTENT, label: 'Repurchase intent' },
];

const {
  getPostOrderFeedbackData: getPostOrderFeedbackDataAction,
  sendAction,
  setTopics,
} = MerchantActionCreators;

const ResponseTable = (
  {
    response: {
      merchantId,
      company,
      phone,
      replyDatetime,
      outgoingMessage,
      incomingMessage,
      sentiment,
      positive,
      negative,
      neutral,
      mixed,
      manualSentiment,
    },
  },
) => (
  <table className="table response">
    <tbody>
      <tr>
        <th>Merchant:</th>
        <td>{`${company} (#${merchantId})`}</td>
      </tr>
      <tr>
        <th>Phone:</th>
        <td>{phone}</td>
      </tr>
      <tr>
        <th>Reply Datetime:</th>
        <td>{formatDate(replyDatetime, FORMAT_YEAR_MONTH_DAY_HOUR_MIN_SEC)}</td>
      </tr>
      <tr>
        <th>Outgoing Message:</th>
        <td>{outgoingMessage}</td>
      </tr>
      <tr>
        <th>Incoming Message:</th>
        <td>{incomingMessage}</td>
      </tr>
      <tr>
        <th>Automated sentiment:</th>
        <td>
          {sentiment}
          {` (${roundToTwoDecimals(positive)} / ${roundToTwoDecimals(negative)} / ${roundToTwoDecimals(neutral)} / ${roundToTwoDecimals(mixed)})`}
        </td>
      </tr>
      <tr>
        <th>Manual sentiment:</th>
        <td>
          {manualSentiment}
        </td>
      </tr>
    </tbody>
  </table>
);

const ResponseActions = ({
  response,
  handleForwardToSupport,
  handleSendReview,
  handleNoAction,
}) => {
  const wrappedHandleForwardToSupport = useCallback(
    () => handleForwardToSupport(response), [response, handleForwardToSupport],
  );
  const wrappedHandleSendReview = useCallback(
    () => handleSendReview(response), [response, handleSendReview],
  );
  const wrappedHandleNoAction = useCallback(
    () => handleNoAction(response), [response, handleNoAction],
  );
  return (
    <div className="actions">
      <div>
        <button onClick={wrappedHandleForwardToSupport} type="submit" className="btn btn-primary">Forward to support</button>
        <button onClick={wrappedHandleSendReview} type="submit" className="btn btn-primary">Send review</button>
        <button onClick={wrappedHandleNoAction} type="submit" className="btn btn-primary">No action</button>
      </div>
    </div>
  );
};

const ResponseTopics = ({
  response,
  handleSetTopics,
}) => {
  const { topics } = response;
  let topicState = {};

  if (Array.isArray(topics)) {
    // eslint-disable-next-line node/no-unsupported-features/es-builtins
    topicState = Object.fromEntries(topics.map((topic) => [topic, true]));
  }

  const wrappedhandleSetTopics = useCallback(
    (currentTopicState) => {
      const parsedTopicState = Object.entries(currentTopicState)
        .filter((entry) => entry[1] === true)
        .map((entry) => entry[0]);

      return handleSetTopics(response, parsedTopicState);
    }, [response, handleSetTopics],
  );

  const handleChange = (event) => {
    const { name, checked } = event.target;

    topicState[name] = checked;

    wrappedhandleSetTopics(topicState);
  };

  return (
    <form onChange={handleChange}>
      <h5>Message Topic</h5>
      {TOPIC_OPTIONS.map((topic) => (
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id={topic.value + response.id}
            name={topic.value}
            defaultChecked={topicState[topic.value] === true}
          />
          <label className="form-check-label" htmlFor={topic.value + response.id}>
            {topic.label}
          </label>
        </div>
      ))}
    </form>
  );
};

const ManualSentiment = ({
  response,
  handleManualSentiment,
}) => {
  const handlePositiveSentiment = useCallback(
    () => handleManualSentiment(response.id, 'POSITIVE'), [response, handleManualSentiment],
  );
  const handleNegativeSentiment = useCallback(
    () => handleManualSentiment(response.id, 'NEGATIVE'), [response, handleManualSentiment],
  );
  const isDisabled = (currentSentiment, sentiment) => currentSentiment === sentiment;
  return (
    <div className="manual-sentiment">
      <div>
        <button disabled={isDisabled(response.manualSentiment, 'POSITIVE')} onClick={handlePositiveSentiment} type="submit" className="btn btn-primary">Positive</button>
        <button disabled={isDisabled(response.manualSentiment, 'NEGATIVE')} onClick={handleNegativeSentiment} type="submit" className="btn btn-primary">Negative</button>
      </div>
    </div>
  );
};

const ResponsesRow = ({
  response,
  handleForwardToSupport,
  handleSendReview,
  handleNoAction,
  handleSetTopics,
  handleManualSentiment,
}) => (
  <tr>
    <td>
      <div className="response-row row">
        <div className="col-md-12 col-xl-12">
          <div className="row">
            <div className="col-md-6 col-xl-6">
              <ResponseTable
                response={response}
              />
            </div>
            <div className="col-md-6 col-xl-6">
              <ResponseTopics
                response={response}
                handleSetTopics={handleSetTopics}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xl-12 pt-3">
              <div className="row">
                <div className="col-md-6 col-xl-6">
                  <ManualSentiment
                    response={response}
                    handleManualSentiment={handleManualSentiment}
                  />
                </div>
                <div className="col-md-6 col-xl-6">
                  <ResponseActions
                    response={response}
                    handleForwardToSupport={handleForwardToSupport}
                    handleSendReview={handleSendReview}
                    handleNoAction={handleNoAction}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </td>
  </tr>
);

const ResponsesTable = ({
  responses,
  handleForwardToSupport,
  handleSendReview,
  handleNoAction,
  handleSetTopics,
  handleManualSentiment,
}) => (
  <table className="table">
    <tbody>
      {responses.map((response) => (
        <ResponsesRow
          key={response.id}
          response={response}
          handleForwardToSupport={handleForwardToSupport}
          handleSendReview={handleSendReview}
          handleNoAction={handleNoAction}
          handleSetTopics={handleSetTopics}
          handleManualSentiment={handleManualSentiment}
        />
      ))}
    </tbody>
  </table>
);

const ResponsesPagination = ({
  total,
  offset,
  limit,
  handleForwardPagination,
  handleBackwardPagination,
}) => {
  const showForwardPagination = offset + limit < total && total > limit;
  const showBackwardPagination = offset >= limit && total > limit;

  return (
    <div>
      {showForwardPagination && <button onClick={handleForwardPagination} type="button">{'>>'}</button>}
      {showBackwardPagination && <button onClick={handleBackwardPagination} type="button">{'<<'}</button>}
    </div>
  );
};

const PostOrderResponses = ({
  postOrderFeedbackData,
  handleForwardToSupport,
  handleSendReview,
  handleNoAction,
  handleSetTopics,
  handleForwardPagination,
  handleBackwardPagination,
  handleManualSentiment,
}) => {
  const {
    responses, total, offset, limit,
  } = postOrderFeedbackData;
  return (
    <div className="post-order-feedback">
      <div className="row">
        <div className="col-md-12 col-xl-12">
          <div className="merch-card">
            {responses && responses.length > 0 && (
              <>
                <ResponsesTable
                  responses={responses}
                  handleForwardToSupport={handleForwardToSupport}
                  handleSendReview={handleSendReview}
                  handleNoAction={handleNoAction}
                  handleSetTopics={handleSetTopics}
                  handleManualSentiment={handleManualSentiment}
                />
                <ResponsesPagination
                  total={total}
                  offset={offset}
                  limit={limit}
                  handleForwardPagination={handleForwardPagination}
                  handleBackwardPagination={handleBackwardPagination}
                />
              </>
            )}
            {responses && responses.length === 0 && (<p>No responses to action</p>)}
          </div>
        </div>
      </div>
    </div>
  );
};

const returnOffsetAboveZeroOrNothing = (offset) => (offset - 1 <= 0 ? '' : offset - 1);

const PostOrderFeedback = memo(
  () => {
    const {
      postOrderFeedbackData,
      postOrderFeedbackLoading,
      postOrderFeedbackLoaded,
    } = useSelector((/** @type {import('../redux/reducers').RootState} */state) => state.MerchantReducer);

    const dispatch = useDispatch();
    const history = useHistory();
    const { offset = 0 } = useParams();

    const parsedOffset = parseInt(offset, 10) > 0 ? parseInt(offset, 10) : 0;
    const forwardOffset = parsedOffset + 1;
    const backwardsOffset = returnOffsetAboveZeroOrNothing(parsedOffset);
    const offsetTimesLimit = parsedOffset * PAGINATION_LIMIT;

    const fetchResponses = useCallback((currentOffset) => dispatch(
      getPostOrderFeedbackDataAction(currentOffset, PAGINATION_LIMIT),
    ), [dispatch]);

    useEffect(() => {
      const {
        total,
      } = postOrderFeedbackData;
      const currentOffsetTimesLimit = parsedOffset * PAGINATION_LIMIT;
      if (total > 0 && currentOffsetTimesLimit + 1 > total) {
        history.push(`/feedback/${returnOffsetAboveZeroOrNothing(parsedOffset)}`);
      }
    }, [history, parsedOffset, postOrderFeedbackData]);

    useEffect(() => {
      fetchResponses(offsetTimesLimit);
    }, [fetchResponses, offsetTimesLimit]);

    const handleForwardToSupport = useCallback(
      async (response) => {
        await dispatch(sendAction({ merchantId: response.merchantId, actionId: response.id }, 'sendForwardToSupportAction'));
        fetchResponses(offsetTimesLimit);
      }, [dispatch, fetchResponses, offsetTimesLimit],
    );
    const handleSendReview = useCallback(
      async (response) => {
        await dispatch(sendAction({ merchantId: response.merchantId, actionId: response.id }, 'sendReviewAction'));
        fetchResponses(offsetTimesLimit);
      }, [dispatch, fetchResponses, offsetTimesLimit],
    );
    const handleNoAction = useCallback(
      async (response) => {
        await dispatch(sendAction({ actionId: response.id }, 'sendNoAction'));
        fetchResponses(offsetTimesLimit);
      }, [dispatch, fetchResponses, offsetTimesLimit],
    );
    const handleSetTopics = useCallback(
      async (response, topics) => {
        dispatch(setTopics({ merchantId: response.merchantId, actionId: response.id, topics }));
      }, [dispatch],
    );
    const handleForwardPagination = useCallback(() => {
      history.push(`/feedback/${forwardOffset}`);
    }, [history, forwardOffset]);
    const handleBackwardPagination = useCallback(() => {
      history.push(`/feedback/${backwardsOffset}`);
    }, [history, backwardsOffset]);

    const handleManualSentiment = useCallback(
      async (actionId, sentiment) => {
        await dispatch(sendAction({ actionId, sentiment }, 'setManualSentiment'));
        fetchResponses(offsetTimesLimit);
      }, [dispatch, fetchResponses, offsetTimesLimit],
    );

    return (
      <PageBuilder>
        <div className="a-view merchant">
          <div className="container">
            <BTitle text="Incoming Feedback Replies" />
            {postOrderFeedbackLoading && (<Loading loading />)}
            {postOrderFeedbackLoaded && !postOrderFeedbackLoading && postOrderFeedbackData && (
              <PostOrderResponses
                postOrderFeedbackData={postOrderFeedbackData}
                handleForwardToSupport={handleForwardToSupport}
                handleSendReview={handleSendReview}
                handleNoAction={handleNoAction}
                handleSetTopics={handleSetTopics}
                handleForwardPagination={handleForwardPagination}
                handleBackwardPagination={handleBackwardPagination}
                handleManualSentiment={handleManualSentiment}
              />
            )}
          </div>
        </div>
      </PageBuilder>
    );
  },
);

export default PostOrderFeedback;
