import { MerchantActionTypes } from '../../types';

export const initialState = {
  loading: false,
  allMerchantsErr: null,
  allMerchants: [],
  merchantErr: null,
  merchantLoaded: false,
  merchantUpdated: false,
  merchantUserUpdated: false,
  merchantAppUpdated: false,
  autorespondersUpdated: false,
  merchantData: {},
  customerSubscriptionLoading: false,
  customerSubscriptionError: null,
  uploadedNumbers: null,
  updatingSettings: false,
  updateSettingsError: null,
  updateSettingsResult: null,
  postOrderFeedbackData: {},
  postOrderFeedbackLoading: false,
  postOrderFeedbackLoaded: false,
  postOrderFeedbackError: null,
};

export const MerchantReducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case MerchantActionTypes.GET_MERCHANTS_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case MerchantActionTypes.GET_MERCHANTS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          allMerchantsErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        allMerchantsErr: null,
        allMerchants: payload.merchants,
      };
    }
    case MerchantActionTypes.GET_MDATA_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case MerchantActionTypes.GET_MDATA_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          merchantErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        merchantLoaded: true,
        merchantData: payload.merchantData,
      };
    }
    case MerchantActionTypes.UPDATE_MDATA_REQ: {
      return {
        ...state,
        loading: true,
        merchantUpdated: false,
      };
    }
    case MerchantActionTypes.UPDATE_MDATA_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          merchantErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        merchantUpdated: true,
        merchantErr: null,
      };
    }
    case MerchantActionTypes.GET_POST_ORDER_DATA_REQ: {
      return {
        ...state,
        postOrderFeedbackLoading: true,
      };
    }
    case MerchantActionTypes.GET_POST_ORDER_DATA_RES: {
      if (error) {
        return {
          ...state,
          postOrderFeedbackLoading: false,
          postOrderFeedbackError: error,
        };
      }
      return {
        ...state,
        postOrderFeedbackLoading: false,
        postOrderFeedbackLoaded: true,
        postOrderFeedbackError: null,
        postOrderFeedbackData: payload.data,
      };
    }
    case MerchantActionTypes.CREATE_MERCHANT_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case MerchantActionTypes.CREATE_MERCHANT_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          merchantErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        merchantErr: null,
        merchantData: payload,
      };
    }
    case MerchantActionTypes.DELETE_MERCH_USER_REQ: {
      return {
        ...state,
        merchantUserUpdated: false,
        loading: true,
      };
    }
    case MerchantActionTypes.DELETE_MERCH_USER_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          merchantErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        merchantErr: null,
        merchantUserUpdated: true,
      };
    }
    case MerchantActionTypes.SAVE_MERCH_USER_REQ: {
      return {
        ...state,
        merchantUserUpdated: false,
        loading: true,
      };
    }
    case MerchantActionTypes.SAVE_MERCH_USER_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          merchantErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        merchantErr: null,
        merchantUserUpdated: true,
      };
    }
    case MerchantActionTypes.UPDATE_MERCH_APP_REQ: {
      return {
        ...state,
        merchantAppUpdated: false,
        loading: true,
      };
    }
    case MerchantActionTypes.UPDATE_MERCH_APP_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          merchantErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        merchantErr: null,
        merchantAppUpdated: true,
      };
    }
    case MerchantActionTypes.CREATE_SUB_REQ: {
      return {
        ...state,
        customerSubscriptionLoading: true,
      };
    }
    case MerchantActionTypes.CREATE_SUB_RES: {
      if (error) {
        return {
          ...state,
          customerSubscriptionLoading: false,
          customerSubscriptionError: error,
        };
      }
      return {
        ...state,
        customerSubscriptionError: null,
        customerSubscriptionLoading: false,
      };
    }
    case MerchantActionTypes.SAVE_AUTORESPONDER_REQ: {
      return {
        ...state,
        autorespondersUpdated: false,
        loading: true,
      };
    }
    case MerchantActionTypes.SAVE_AUTORESPONDER_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          merchantErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        merchantErr: null,
        autorespondersUpdated: true,
        merchantData: {
          ...state.merchantData,
          autoresponders: payload.list,
        },
      };
    }
    case MerchantActionTypes.CREATE_AUTORESPONDER_REQ: {
      return {
        ...state,
        autorespondersUpdated: false,
        loading: true,
      };
    }
    case MerchantActionTypes.CREATE_AUTORESPONDER_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          merchantErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        merchantErr: null,
        autorespondersUpdated: true,
        merchantData: {
          ...state.merchantData,
          autoresponders: payload.list,
        },
      };
    }
    case MerchantActionTypes.UPLOAD_NUMBERS_REQ: {
      return {
        ...state,
        loading: true,
        merchantErr: null,
        uploadedNumbers: null,
      };
    }
    case MerchantActionTypes.UPLOAD_NUMBERS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          merchantErr: error,
        };
      }

      return {
        ...state,
        loading: false,
        merchantErr: null,
        uploadedNumbers: payload,
      };
    }
    case MerchantActionTypes.UPDATE_MERCH_SETTINGS_REQ: {
      return {
        ...state,
        updatingSettings: true,
        updateSettingsError: null,
        updateSettingsResult: null,
      };
    }
    case MerchantActionTypes.UPDATE_MERCH_SETTINGS_RES: {
      if (error) {
        return {
          ...state,
          updatingSettings: false,
          updateSettingsError: error,
          updateSettingsResult: null,
        };
      }

      return {
        ...state,
        updatingSettings: false,
        updateSettingsError: null,
        updateSettingsResult: payload.message,
      };
    }
    case MerchantActionTypes.SEND_FEEDBACK_ACTION_REQ: {
      return {
        ...state,
        postOrderFeedbackLoading: true,
      };
    }
    case MerchantActionTypes.SEND_FEEDBACK_ACTION_RES: {
      return {
        ...state,
        postOrderFeedbackLoading: false,
      };
    }
    case MerchantActionTypes.RESET_STATE: {
      return {
        ...state,
        loading: false,
        merchantErr: null,
        uploadedNumbers: null,
      };
    }
    case 'LOGOUT': {
      return initialState;
    }
    default:
      return state;
  }
};
