import React, { useState } from 'react';

export const BPTabs = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const { tabs } = props;
  return (
    <div className="bp-tabs">
      <div className="bp-tabs-header">
        {tabs.map((tab, index) => (
          // eslint-disable-next-line react/jsx-key
          <div
            className={`tab-item${index === activeTab ? ' active' : ''}`}
            onClick={() => setActiveTab(index)}
            role="button"
            tabIndex={index}
            key={tab.title}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div className="bp-tabs-body">
        {tabs && tabs.length ? (
          <div className="tab-body active">{tabs[activeTab].content}</div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default BPTabs;
