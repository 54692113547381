import React from 'react';

const BTitle = (props) => {
  const { text, className = '' } = props;
  return (
    <span className={`btitle ${className}`}>
      {text}
      <span className="dot">.</span>
    </span>
  );
};

export default BTitle;
