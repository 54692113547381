const validator = require('validator');

const containsOnlyDigits = (string) => (/^\d+$/.test(string));

export const newMerchantValidate = (values) => {
  const errors = {};

  if (!values.storeCurrency) {
    errors.storeCurrency = 'Please select a currency';
  }

  // Store name should be provided
  if (!values.storeName) {
    errors.storeName = 'Required';
  }

  // a valid URL should be provided for store custom domain
  const { storeCustomDomain } = values;
  if (!storeCustomDomain) {
    errors.storeCustomDomain = 'Required';
  } else {
    const isStoreCustomDomainURL = validator.isURL(storeCustomDomain);
    if (!isStoreCustomDomainURL) {
      errors.storeCustomDomain = 'Please input a valid URL here';
    } else if (
      isStoreCustomDomainURL
      && storeCustomDomain[storeCustomDomain.length - 1] === '/'
    ) {
      errors.storeCustomDomain = 'Please remove the appending forward slash';
    }
  }

  // a valid phone number should be provided for store phone
  // Even when a phone number is valid, it should not contain anything other than digits
  if (!values.storePhone) {
    errors.storePhone = 'Required';
  } else if (!validator.isMobilePhone(values.storePhone) || !containsOnlyDigits(values.storePhone)) {
    errors.storePhone = 'Please enter a valid phone number with this format: 447857033883';
  }

  // a valid email should be provided for initial user email
  if (!values.initialUserEmail) {
    errors.initialUserEmail = 'Required';
  } else if (!validator.isEmail(values.initialUserEmail)) {
    errors.initialUserEmail = 'Please enter a valid email';
  }

  // username should contain only english letters
  if (!values.initialUsername) {
    errors.initialUsername = 'Required';
  } else if (!validator.isAlpha(values.initialUsername)) {
    errors.initialUsername = 'The username should only contain letters (a-zA-Z)';
  }

  // password should meet the minimum requirement
  if (!values.initialUserPassword) {
    errors.initialUserPassword = 'Required';
  } else if (
    !validator.isStrongPassword(values.initialUserPassword, {
      minLength: 10,
      minNumbers: 1,
      minLowercase: 0,
      minUppercase: 0,
      minSymbols: 0,
    })
  ) {
    errors.initialUserPassword = 'The password should contain at least 10 characters and 1 number';
  }

  // store's shopify domain should contain ".myshopify.com"
  if (!values.shopifyDomain) {
    errors.shopifyDomain = 'Required';
  } else if (!validator.contains(values.shopifyDomain, '.myshopify.com')) {
    errors.shopifyDomain = 'Shopify domain should have ".myshopify.com" part';
  } else if (values.shopifyDomain.includes('http')) {
    errors.shopifyDomain = 'Shopify domain should not include the "http" part';
  } else if (!values.shopifyDomain.endsWith('.myshopify.com')) {
    errors.shopifyDomain = 'The domain name should only end with .myshopify.com';
  } else if (/\s/.test(values.shopifyDomain)) {
    errors.shopifyDomain = 'The domain name should not contain any white spaces';
  }

  // No need to validate custom app inputs if a public app is used
  if (values.storeUsePublicApp) {
    return errors;
  }

  // Check for shopify app api key length
  if (!values.shopifyAppKey) {
    errors.shopifyAppKey = 'Required';
  } else if (values.shopifyAppKey.length < 30) {
    errors.shopifyAppKey = 'It is too short for an api key';
  }

  // Shopify custom app's secret should contain "shpss_"
  if (!values.shopifyAppSecret) {
    errors.shopifyAppSecret = 'Required';
  } else if (!validator.contains(values.shopifyAppSecret, 'shpss_')) {
    errors.shopifyAppSecret = 'Shopify app secret should contain "shpss_"';
  } else if (values.shopifyAppKey.length < 30) {
    errors.shopifyAppKey = 'It is too short for an api secret';
  }

  return errors;
};

const validDateRegex = new RegExp('[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]');

export const brandDetailsValidate = (values) => {
  const errors = {};
  const HUBSPOT_DEAL_ID_MAX_LEN = 32;

  if (values.goLiveDate) {
    if (!validDateRegex.test(values.goLiveDate)) {
      errors.goLiveDate = 'Enter valid date format YYYY-MM-DD HH:MM:SS';
    }
  }

  if (values.hubspotDealId) {
    if (values.hubspotDealId.length > HUBSPOT_DEAL_ID_MAX_LEN) {
      errors.hubspotDealId = `The deal id length should not exceed ${HUBSPOT_DEAL_ID_MAX_LEN} characters`;
    }
  }

  return errors;
};

export const updatePasswordValidate = (values) => {
  const errors = {};
  if (!values.currentPassword) {
    errors.currentPassword = 'Required';
  } else if (values.currentPassword.length < 6) {
    errors.currentPassword = 'Password should be at least 6 characters';
  }

  if (!values.newPassword) {
    errors.newPassword = 'Required';
  } else if (values.newPassword.length < 6) {
    errors.newPassword = 'Password should be at least 6 characters';
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'Required';
  } else if (values.confirmPassword.length < 6) {
    errors.confirmPassword = 'Password should be at least 6 characters';
  }

  if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = 'Passwords should match';
  }
  return errors;
};

export const userDetailsValidate = (values) => {
  const errors = {};

  if (values.operation === 'addNew' && !values.name) {
    errors.name = 'Required';
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (!validator.isEmail(values.email)) {
    errors.email = 'Please enter a valid email';
  }

  if (!values.role) {
    errors.role = 'Required';
  }

  return errors;
};

export const shopifyAppValidate = (values) => {
  const errors = {};

  if (!values.storeDomain) {
    errors.storeDomain = 'Required';
  }

  if (!values.apiKey) {
    errors.apiKey = 'Required';
  }

  if (!values.apiSecret) {
    errors.apiSecret = 'Required';
  }

  return errors;
};

export const autoresponderValidate = (values) => {
  const errors = {};

  if (!values.triggerText) {
    errors.triggerText = 'Required';
  }

  if (values.alternativeUrl && !values.alternativeUrl.startsWith('https://')) {
    errors.alternativeUrl = 'Invalid URL - start with https://';
  }

  return errors;
};

export const stripeCustomerIdValidate = (value) => ((value && value.startsWith('cus_')) || !value
  ? undefined : 'Stripe Customer ID starts with "cus_"');

export const stripePlanIdValidate = (value) => ((value && value.startsWith('price_')) || !value
  ? undefined : 'Stripe Plan ID starts with "price_"');

export const subscriptionItemIdValidate = (value) => ((value && value.startsWith('si_')) || !value
  ? undefined : 'Stripe Subscription Item ID starts with "si_"');
