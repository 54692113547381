import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AuthActionCreators from '../redux/actions/auth';
import BTitle from '../components/global/BTitle';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '', password: '', errors: {}, loading: false,
    };
  }

  setValue = (key, value) => this.setState({ [key]: value });

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password, loading } = this.state;
    if (!loading) {
      const errors = {};
      this.setState({ errors, loading: true });
      if (!email) {
        errors.email = 'Required';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email)) {
        errors.email = 'Invalid email';
      }
      if (!password || password.length < 6) {
        errors.password = 'Required';
      }
      if (Object.keys(errors).length > 0) {
        this.setState({ errors, loading: false });
      } else {
        const { actions } = this.props;
        actions
          .login({ email, password })
          .then(() => {
            const { loginErr, history } = this.props;
            if (loginErr) {
              this.setState({ errors: { join: loginErr }, loading: false });
            } else {
              history.push('/');
            }

            return null;
          })
          .catch((err) => {
            this.setState({
              errors: { join: err.message || 'Sorry, something went wrong' },
              loading: false,
            });
          });
      }
    }
  };

  render() {
    const {
      email, password, errors, loading,
    } = this.state;
    return (
      <div className="a-view login">
        <div className="container">
          <div className="login-card">
            <div className="login-title">
              <BTitle text="blueprint" />
              <span className="admin">admin</span>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-container">
                <div className="form-content">
                  <div className="wl-input-group">
                    {errors.email && (
                      <div className="input-err-message">{errors.email}</div>
                    )}
                    <input
                      type="email"
                      className={
                        `form-control${errors.email ? ' error' : ''}`
                      }
                      placeholder="Email"
                      value={email}
                      onChange={(e) => this.setValue('email', e.target.value)}
                    />
                  </div>
                  <div className="wl-input-group">
                    {errors.password && (
                      <div className="input-err-message">{errors.password}</div>
                    )}
                    <input
                      type="password"
                      className={
                        `form-control${errors.password ? ' error' : ''}`
                      }
                      placeholder="Password"
                      value={password}
                      onChange={(e) => this.setValue('password', e.target.value)}
                    />
                  </div>
                  {errors.join && (
                    <div className="form-error">{errors.join}</div>
                  )}
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading ? 'Logging In...' : 'Login'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginErr: state.AuthReducer.loginErr,
  isAuthed: state.AuthReducer.isAuthed,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(AuthActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
