import React from 'react';
import { DateTime } from 'luxon';
import { Form, Field } from 'react-final-form';
import BPField from './BPField';
import { BPToggle } from '../BPToggle';
import { stripeCustomerIdValidate, stripePlanIdValidate, subscriptionItemIdValidate } from '../../utils/validation';

const YMAL_FILTER_MODES = {
  OFF: 'OFF',
  ALLOW: 'ALLOW',
  BLOCK: 'BLOCK',
};

const getOptInSource = (sources, source) => {
  const matchedSource = sources.find((data) => data.source === source);
  return matchedSource?.goLiveDate || undefined;
};

const attemptJSONStringify = (rawJson, replacer, space) => (rawJson
  ? JSON.stringify(rawJson, replacer, space) : null);

const OptInMessageField = ({ optInMessageObj }) => (
  <div>
    <div className="form-group brand-color">
      <label htmlFor="optInMessage">Opt In message</label>
      <div className="field">
        <Field
          name="optInMessage"
          component="textarea"
          className="form-control"
          initialValue={optInMessageObj ? optInMessageObj.messageContent : ''}
        />
      </div>
      <i>Leave this empty to use the default opt-in message</i>
    </div>
  </div>
);

const OptInSourcesField = ({ name, value }) => (
  <div>
    <div className="form-group brand-color">
      <label htmlFor={name}>
        Go live date for
        {' '}
        {name}
      </label>
      <div className="field">
        <Field
          name={name}
          component={BPField}
          type="date"
          className="form-control"
          initialValue={DateTime.fromISO(value).toISODate()}
        />
      </div>
    </div>
  </div>
);

const optInSourcesFields = (initialValues) => {
  const { optInSourceData, optInSources, optInMessage: optInMessageObj } = initialValues;
  return (
    <div>
      <h6 className="mt-4">Opt In Settings</h6>
      {Object.entries(optInSources).map(([, value]) => (
        <OptInSourcesField name={value} key={value} value={getOptInSource(optInSourceData, value)} />
      ))}
      <OptInMessageField optInMessageObj={optInMessageObj} />
    </div>
  );
};

const BillingFields = ({ billingData, merchant }) => (
  <div>
    <h6 className="mt-4">Billing Settings</h6>
    <div className="form-group brand-color">
      <label htmlFor="stripeCustomerId">Stripe Customer ID</label>
      <div className="field">
        <Field
          name="stripeCustomerId"
          component={BPField}
          type="text"
          className="form-control"
          validate={stripeCustomerIdValidate}
          placeholder="cus_"
          initialValue={billingData ? billingData.stripeCustomerId : ''}
        />
      </div>
    </div>
    <div className="form-group brand-color">
      <label htmlFor="stripePlanId">Stripe Plan ID</label>
      <div className="field">
        <Field
          name="stripePlanId"
          component={BPField}
          type="text"
          className="form-control"
          validate={stripePlanIdValidate}
          placeholder="price_"
          initialValue={billingData ? billingData.stripePlanId : ''}
        />
      </div>
    </div>
    <div className="form-group brand-color">
      <label htmlFor="subscriptionItemId">Subscription Item ID</label>
      <div className="field">
        <Field
          name="subscriptionItemId"
          component={BPField}
          type="text"
          className="form-control"
          validate={subscriptionItemIdValidate}
          placeholder="si_"
          initialValue={billingData ? billingData.subscriptionItemId : ''}
        />
      </div>
    </div>
    <div className="form-group brand-color">
      <label htmlFor="isExcusedFromNoBilling">Allow access to app without providing billing info</label>
      <div className="field">
        <Field
          name="isExcusedFromNoBilling"
          component={BPToggle}
          type="checkbox"
          className="form-control"
          initialValue={merchant.isExcusedFromNoBilling}
        />
      </div>
    </div>
  </div>
);

const EnablementFields = ({ merchant }) => (
  <div>
    <h6 className="mt-4">Enablement Settings</h6>
    <div className="form-group brand-color">
      <label htmlFor="isMessageSendingAllowed">Allow SMS sending</label>
      <div className="field">
        <Field
          name="isMessageSendingAllowed"
          component={BPToggle}
          type="checkbox"
          className="form-control"
          initialValue={merchant.isMessageSendingAllowed}
        />
      </div>
    </div>
    <div className="form-group brand-color">
      <label htmlFor="shopifySMSConsentCaptureEnabled">Shopify SMS specific opt-in consent</label>
      <div className="field">
        <Field
          name="shopifySMSConsentCaptureEnabled"
          component={BPToggle}
          type="checkbox"
          className="form-control"
          initialValue={merchant.merchantSettings?.shopifySMSConsentCaptureEnabled}
        />
      </div>
    </div>
    <div className="form-group brand-color">
      <label htmlFor="isV1Enabled">Is V1 enabled?</label>
      <div className="field">
        <Field
          name="isV1Enabled"
          component={BPToggle}
          type="checkbox"
          className="form-control"
          initialValue={merchant.isV1Enabled}
        />
      </div>
    </div>
    <div className="form-group brand-color">
      <label htmlFor="isV2Enabled">Is V2 enabled?</label>
      <div className="field">
        <Field
          name="isV2Enabled"
          component={BPToggle}
          type="checkbox"
          className="form-control"
          initialValue={merchant.isV2Enabled}
        />
      </div>
    </div>
    <div className="form-group brand-color">
      <label htmlFor="isUpdateToSubscriptionModelEnabled">Is V2 upgrade to sub enabled?</label>
      <div className="field">
        <Field
          name="isUpdateToSubscriptionModelEnabled"
          component={BPToggle}
          type="checkbox"
          className="form-control"
          initialValue={merchant.isUpdateToSubscriptionModelEnabled}
        />
      </div>
    </div>
  </div>
);

const PortalFields = ({ merchant }) => {
  const cssVariablesInitial = attemptJSONStringify(merchant?.merchantPortalSettings?.cssVariables, null, 2);

  return (
    <div>
      <h6 className="mt-4">Portal Settings</h6>
      <div className="form-group brand-color">
        <label htmlFor="portalLogoUri">Logo URI</label>
        <div className="field">
          <Field
            name="portalLogoUri"
            component={BPField}
            type="text"
            className="form-control"
            initialValue={merchant?.merchantPortalSettings?.logoUri || ''}
          />
        </div>
      </div>
      <div className="form-group brand-color">
        <label htmlFor="portalCssVariables">CSS variables</label>
        <div className="field">
          <Field
            name="portalCssVariables"
            component="textarea"
            className="form-control"
            rows="6"
            initialValue={cssVariablesInitial}
          />
        </div>
      </div>
      <div className="form-group brand-color">
        <label htmlFor="portalOneTimeProducts">One-time Product Options</label>
        <div className="field">
          <Field
            name="portalOneTimeProducts"
            component="textarea"
            className="form-control"
            rows="3"
            initialValue={merchant?.merchantPortalSettings?.oneTimeProducts}
          />
        </div>
        <em>
          A comma-separated list of external product variant Ids, which will be
          presented as One-time product options in the Portal
        </em>
      </div>
    </div>
  );
};

const MagicCartFields = ({ merchant }) => {
  const merchantRecommendedProductsInitial = attemptJSONStringify(
    merchant?.merchantPortalSettings?.merchantRecommendedProducts, null, null,
  );
  const recommendedProductsFilterProductsInitial = attemptJSONStringify(
    merchant?.merchantPortalSettings?.recommendedProductsFilterProducts, null, null,
  );
  const customCSSInitial = merchant?.merchantPortalSettings?.customCss || '';

  return (
    <div>
      <h6 className="mt-4">Magic Cart Settings</h6>
      <div className="form-group brand-color">
        <label htmlFor="portalOneTimeProducts">YMAL Product Options</label>
        <div className="field">
          <Field
            name="magicCartMerchantRecommendedProducts"
            component="textarea"
            className="form-control"
            rows="3"
            initialValue={merchantRecommendedProductsInitial}
          />
        </div>
        <em>
          An array [1234, 5678] of up to 3 external product ids that the
          merchant wishes to appear first in the YMAL section
        </em>
      </div>
      <div className="form-group brand-color">
        <label htmlFor="portalCssVariables">YMAL Filter Mode</label>
        <div className="field">
          <Field
            name="magicCartRecommendedProductsFilterMode"
            component="select"
            className="form-control"
            initialValue={merchant?.merchantPortalSettings?.recommendedProductsFilterMode}
          >
            <option value={YMAL_FILTER_MODES.OFF}>Not enabled</option>
            <option value={YMAL_FILTER_MODES.ALLOW}>Allow only those listed</option>
            <option value={YMAL_FILTER_MODES.BLOCK}>Block those listed</option>
          </Field>
        </div>
        <em>Which mode should YMAL filtering operate in?</em>
      </div>
      <div className="form-group brand-color">
        <label htmlFor="portalOneTimeProducts">YMAL Product Options</label>
        <div className="field">
          <Field
            name="magicCartRecommendedProductsFilterProducts"
            component="textarea"
            className="form-control"
            rows="3"
            initialValue={recommendedProductsFilterProductsInitial}
          />
        </div>
        <em>
          An array [1234, 5678] of external product ids that the
          merchant wishes to apply the above filter rule to the
          Shopify recommendations displayed
        </em>
      </div>
      <div className="form-group brand-color">
        <label htmlFor="magicCartCustomCss">Custom CSS Rules</label>
        <div className="field">
          <Field
            name="magicCartCustomCss"
            component="textarea"
            className="form-control"
            rows="3"
            initialValue={customCSSInitial}
          />
        </div>
        <em>
          CSS Rules to apply to the Magic Cart
        </em>
      </div>
    </div>
  );
};

const SettingsForm = ({
  initialValues,
  loading,
  onSubmit,
}) => (
  <Form
    onSubmit={onSubmit}
    render={({
      handleSubmit,
      submitError,
      submitting,
      pristine,
      valid,
    }) => (
      <form onSubmit={handleSubmit} className="merchant-settings-form">
        <div className="row">
          <div className="col-md-6 col-xl-6">
            {optInSourcesFields(initialValues)}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xl-6">
            <BillingFields
              billingData={initialValues.billing ? initialValues.billing[0] : null}
              merchant={initialValues}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xl-6">
            <EnablementFields
              merchant={initialValues}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xl-6">
            <PortalFields
              merchant={initialValues}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xl-6">
            <MagicCartFields
              merchant={initialValues}
            />
          </div>
        </div>
        {submitError && <span className="form-error">{submitError}</span>}
        <div className="form-group buttons">
          <button
            type="submit"
            disabled={!valid || submitting || pristine}
            className="btn btn-primary"
          >
            {loading && 'Saving...' }
            {!loading && 'Save Changes' }
          </button>
        </div>
      </form>
    )}
  />
);

export default SettingsForm;
