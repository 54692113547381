import axios from 'axios';

export const APIMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

/**
 * @param {String?} [fullSource]
 * @param {import('axios').AxiosRequestConfig} options
 * @returns
 */
const apiRequest = (
  method,
  headers = {},
  SERVICE,
  ENDPOINT,
  inputData,
  formData,
  fullSource = null,
  jsonify = true,
  options = {},
) => new Promise((resolve, reject) => {
  const URL = fullSource || `${window.blueprintGetApiBaseURL()}/${SERVICE}/${ENDPOINT}`;
  const sessionToken = localStorage.getItem('sToken');

  let axiosRequest;

  if (formData) {
    axiosRequest = {
      ...options,
      method,
      url: URL,
      headers: { Authorization: sessionToken, ...headers },
      data: formData,
    };
  } else {
    const { sessionToken: sessionTokenFromParam, ...params } = inputData;

    const data = jsonify
      ? JSON.stringify({ ...inputData })
      : { ...inputData };

    axiosRequest = {
      ...options,
      method,
      url: URL,
      headers: { Authorization: sessionToken, ...headers },
      ...(method !== APIMethods.GET && { data }),
      ...(method === APIMethods.GET && { params }),
    };
  }

  axios(axiosRequest)
    .then((response) => resolve(response))
    .catch(async (error) => {
      if (error.response && error.response.status === 401) {
        const refreshToken = localStorage.getItem('rToken');
        // eslint-disable-next-line promise/no-nesting
        axios
          .post(
            `${window.blueprintGetApiBaseURL()}/blueprint-admin-backend/token`,
            JSON.stringify({
              operation: 'refresh',
              sessionToken,
              refreshToken,
            }),
          )
          .then(async (response) => {
            const { sessionToken: newSessionToken } = response.data;
            localStorage.setItem('sToken', newSessionToken);

            let updatedAxiosRequest;

            if (formData) {
              updatedAxiosRequest = {
                ...axiosRequest,
                headers: {
                  Authorization: newSessionToken,
                  ...headers,
                },
              };
            } else {
              const updatedData = jsonify
                ? JSON.stringify({
                  ...inputData,
                  sessionToken: newSessionToken,
                })
                : { ...inputData, sessionToken: newSessionToken };

              updatedAxiosRequest = {
                ...axiosRequest,
                headers: {
                  Authorization: newSessionToken,
                  ...headers,
                },
                ...(method !== APIMethods.GET && { data: updatedData }),
              };
            }

            const resp = await axios(updatedAxiosRequest);

            return resolve(resp);
          })
          .catch((err) => {
            console.log(err.response);
            reject(err.response || err);
          });
      } else {
        reject(error.response || error);
      }
    });
});

/**
 * @param {import('axios').AxiosRequestConfig} options
 * @returns
 */
export const API = (
  method = APIMethods.GET,
  SERVICE,
  ENDPOINT,
  inputData,
  formData,
  fullSource = null,
  jsonify = true,
  options = {},
) => apiRequest(
  method,
  {},
  SERVICE,
  ENDPOINT,
  inputData,
  formData,
  fullSource,
  jsonify,
  options,
);

/**
 * @param {String?} [fullSource]
 */
export const BlueprintAPI = (
  SERVICE,
  ENDPOINT,
  inputData,
  fullSource = null,
  jsonify = true,
  method = 'post',
) => apiRequest(
  method,
  { 'Content-type': 'application/json' },
  SERVICE,
  ENDPOINT,
  inputData,
  null,
  fullSource,
  jsonify,
);
