import React, { useState } from 'react';
import ShopifyAppsTable from './ShopifyAppsTable';
import { ShopifyAppForm } from './forms/ShopifyAppForm';

export const ShopifyAppsTab = ({ merchant, handleSubmit, loading }) => {
  const [app, setApp] = useState({});
  const onClick = (appParam) => {
    setApp(appParam);
  };
  return (
    <div>
      <div className="row">
        <div className="col-md-6 col-xl-7">
          <div className="merch-card">
            <span className="merch-title">Shopify Apps</span>
            <ShopifyAppsTable apps={merchant.shopifyApps} onClick={onClick} />
          </div>
        </div>
        <div className="col-md-6 col-xl-5">
          <div className="merch-card">
            <span className="merch-title">Shopify App</span>
            <ShopifyAppForm loading={loading} initialValues={app} onSubmit={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopifyAppsTab;
